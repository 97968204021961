<template>
<div>
  <div class="next-schedule" style="margin-bottom: 30px">
    <label style="display: block">Scheduled maintenance or downtime:</label>
    <textarea v-model="scheduled" style="width: 100%; height: 100px"></textarea>
  </div>
  <div class="content-table-container">
    <table class="content-table">
      <thead>
        <tr>
          <th style="width: 14%">Name</th>
          <th style="width: 63%">Down message</th>
          <th style="width: 11%">Color</th>
          <th style="width: 11%">Down time</th>
          <th style="width: 1%">&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        <template v-for="(item, index) in items">
        <tr style="background: #fff">
          <td class="main-col" colspan="4" style="background: #fff">
            <input style="text-align: left" type="text" v-model="item.title" />
          </td>
          <td style="padding-left: 10px; padding-right: 0; background: #fff">
            <a class="remove" href="" v-on:click.prevent="items.splice(index, 1)">&times;</a>
          </td>
        </tr>
        <tr v-for="(subitem, si) in item.items">
          <td class="main-col" style="padding-left: 20px">
            <input style="text-align: left" type="text" class="fullw" v-model="subitem.name" />
          </td>
          <td class="">
            <input style="text-align: left" class="fullw" type="text" v-model="subitem.downMsg" />
          </td>
          <td class="">
            <dyn-select class="fullw" style="text-align: left" v-model="subitem.color" :options="[{value: 'green', label: 'Green'},{value: 'yellow', label: 'Yellow'},{value: 'red', label: 'Red'},{value: 'black', label: 'Black'}]" />
          </td>
          <td class="">
            <dyn-select class="fullw" style="text-align: left" v-model="subitem.downTime" :options="[{value: 'Operational', label: 'Operational'},{value: 'Maintenance', label: 'Maintenance'},{value: 'Down', label: 'Down'},{value: 'Offline', label: 'Offline'}]" />
          </td>
          <td style="padding-left: 10px; padding-right: 0">
            <a class="remove" href="" v-on:click.prevent="item.items.splice(si, 1)">&times;</a>
          </td>
        </tr>
          <td colspan="5">
            <a class="add" href="" v-on:click.prevent="newItem(index)">+ Item</a>
          </td>
        
        </template>
        <tr class="new-row">
          <td colspan="5">
            <a class="add" href="" v-on:click.prevent="newCategory">+ Category</a>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="content-filters">
    <button class="main" v-on:click.prevent="updateStatus">Submit</button>
  </div>
</div>
</template>

<script>
export default {
  data() { return {
    items: [],
    scheduled: "",
  }},
  methods: {
    reload: async function() {
      var opt=((await api.get("Options", 1)).item || {});
      this.items=opt.systemStatus.items || [];
      this.scheduled=opt.systemStatus.scheduled || "";
    },
    updateStatus: async function() {
      startLoading();
      api.update("Options", 1, {systemStatus: {items: this.items, scheduled: this.scheduled}});
      stopLoading("Status updated");
    },
    newCategory() {
      this.items.push({title: "", items: []});
    },
    newItem(index) {
      this.items[index].items.push({
        name: "",
        downMsg: "",
        color: "",
        downTime: "",
      });
    },
  },
  mounted() {
    this.reload();
  },
}

</script>
