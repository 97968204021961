var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form-item",
    {
      class: { full: _vm.full },
      attrs: {
        label: _vm.label,
        validator: _vm.validator,
        "no-style": _vm.noStyle,
        "no-margin": _vm.noMargin
      }
    },
    [
      _vm.type === "checkbox"
        ? _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.val,
                expression: "val"
              }
            ],
            class: _vm.classes,
            attrs: {
              placeholder: "Enter " + (_vm.label || "text"),
              disabled: _vm.disabled,
              maxlength: _vm.maxlength,
              type: "checkbox"
            },
            domProps: {
              checked: Array.isArray(_vm.val)
                ? _vm._i(_vm.val, null) > -1
                : _vm.val
            },
            on: {
              blur: function($event) {
                return _vm.$emit("blur")
              },
              change: [
                function($event) {
                  var $$a = _vm.val,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 && (_vm.val = $$a.concat([$$v]))
                    } else {
                      $$i > -1 &&
                        (_vm.val = $$a.slice(0, $$i).concat($$a.slice($$i + 1)))
                    }
                  } else {
                    _vm.val = $$c
                  }
                },
                function($event) {
                  return _vm.$emit("change", $event)
                }
              ]
            }
          })
        : _vm.type === "radio"
        ? _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.val,
                expression: "val"
              }
            ],
            class: _vm.classes,
            attrs: {
              placeholder: "Enter " + (_vm.label || "text"),
              disabled: _vm.disabled,
              maxlength: _vm.maxlength,
              type: "radio"
            },
            domProps: { checked: _vm._q(_vm.val, null) },
            on: {
              blur: function($event) {
                return _vm.$emit("blur")
              },
              change: [
                function($event) {
                  _vm.val = null
                },
                function($event) {
                  return _vm.$emit("change", $event)
                }
              ]
            }
          })
        : _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.val,
                expression: "val"
              }
            ],
            class: _vm.classes,
            attrs: {
              placeholder: "Enter " + (_vm.label || "text"),
              disabled: _vm.disabled,
              maxlength: _vm.maxlength,
              type: _vm.type
            },
            domProps: { value: _vm.val },
            on: {
              blur: function($event) {
                return _vm.$emit("blur")
              },
              change: function($event) {
                return _vm.$emit("change", $event)
              },
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.val = $event.target.value
              }
            }
          })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }