<template>
  <div class="cof-box">
    <div :class="classes" class="cof-form">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: 'Form'
    },

    classes: {
      type: Object,
      default: null
    }
  }
}
</script>

<style lang="scss" scoped>
  .form-resized {
    width: 40%;
    min-width: 420px;
  }
</style>