<template>
  <form-item :label="label" :validator="validator" :class="{ full }" :no-style="noStyle" :no-margin="noMargin">
    <input
      :class="classes"
      v-model="val"
      :type="type"
      :placeholder="`Enter ${label || 'text'}`"
      :disabled="disabled"
      :maxlength="maxlength"
      @blur="$emit('blur')"
      @change="$emit('change', $event)">
  </form-item>
</template>

<script>
import inputMixin from './input-mixin.js'

export default {
  mixins: [inputMixin],

  props: {
    type: {
      type: String,
      default: 'text'
    },

    full: {
      type: Boolean,
      default: false
    },

    disabled: {
      type: Boolean,
      default: false
    },

    noMargin: {
      type: Boolean,
      default: false
    },

    noStyle: {
      type: Boolean,
      default: false
    },

    classes: {
      type: Object,
      default: null
    },

    maxlength: {
      type: Number,
      default: null
    }
  }
}
</script>

<style lang="scss" scoped>
  .full {
    width: 100%;
  }
</style>