<template>
  <div>
    <section>
      <em class="pop-show add-emp" @click="add = true">+ New Integration</em>
    </section>
    <section class="content-filters">
      <div class="cof-box">
        <a href="" class="tsm-handle">Filters</a>
        <!--   <h4>Transaction Search</h4> -->
        <div class="cof-form">
          <a class="cof-close"></a>
          <!--    <h6>Transaction Search</h6> -->
            <div class="cof-row">
              <div class="cof-field">
                <label>System</label>
                <select v-model="form.name">
                  <option value="">-</option>
                  <option v-bind:value="system.name" v-bind:data-index="index" v-for="(system, index) in systems">
                    {{ system.name }}
                  </option>
                </select>
              </div>
              <div class="cof-field">
                <label>Provider</label>
                <select v-model="form.provider">
                  <option value="">-</option>
                  <option v-bind:value="provider.shortname" v-bind:data-index="index"
                          v-for="(provider, index) in providers">
                    {{ provider.shortname }}
                  </option>
                </select>
              </div>
              <div class="cof-field mobinl">
                <label>&nbsp;</label>
                <button class="main" @click="search()">Filter</button>
              </div>
              <div class="cof-field mobinl">
                <label>&nbsp;</label>
                <button class="second" @click="resetItem()">Reset Fields</button>
              </div>
            </div>
        </div>
      </div>
    </section>
    <section class="content-tlist">
      <table class="content-table">
        <tr>
          <th>Integration ID</th>
          <th>System</th>
          <th>Version</th>
          <th>Provider</th>
          <th>Version</th>
          <th>Certified Date</th>
          <th>Connections</th>
          <th>Status</th>
          <th>Notes</th>
          <th>Options</th>
        </tr>
        <tr v-for="(item, index) in data">
          <td><span>Integration ID</span>
           <a v-on:click="popupIntegration(item)">{{item.integrationId}}</a>
          </td>
          <td><span>System</span>
            <template v-if="!isEdit(index)">{{item.system}}</template>
            <select v-else v-model="item.system">
              <option value="">-</option>
              <option v-bind:value="system.name" v-bind:data-index="index" v-for="(system, index) in systems">
                {{ system.name }}
              </option>
            </select>
          </td>
          <td><span>Version</span>
            <template v-if="!isEdit(index)">{{item.systemVersion}}</template>
            <input type="text" v-else v-model="item.systemVersion" />
          </td>
          <td><span>Provider</span>
            <template v-if="!isEdit(index)"><a v-on:click="popupProvider(index)">{{item.providerShortname}}</a></template>
            <select v-else v-model="item.provider" required>
              <option value="">-</option>
              <option v-bind:value="provider.provider" v-bind:data-index="index"
                      v-for="(provider, index) in providers">
                {{ provider.provider }}
              </option>
            </select>
          </td>
          <td><span>Version</span>
            <template v-if="!isEdit(index)">{{item.providerVersion}}</template>
            <input type="text" v-else v-model="item.providerVersion" />
          </td>
          <td><span>Certified Date</span>
            <template v-if="!isEdit(index)">{{item.certDate}}</template>
            <vue-englishdatepicker v-else v-model="item.certDate"/>
          </td>
          <td><span>Connections</span>
            <template v-if="!isEdit(index)">{{item.connections}}</template>
            <input type="text" v-else v-model="item.connections" />
          </td>
          <td v-on:click="openUpdateStatus(item.id, item.status)"><span>Status</span><em v-if="item.status === 'Active'">Active</em><em v-if="item.status !== 'Active'" class="c2">{{item.status}}</em></td>
          <td><span>Notes</span>
            <template v-if="!isEdit(index)">{{item.notes}}</template>
            <input type="text" v-else v-model="item.notes" />
          </td>
          <td><span>Options</span>
            <template v-if="isEdit(index)">
              <button @click="saveUpdate(index)" class="button-handle save">Save</button>
              <button @click="cancelEdit(index)" class="button-handle cancel">Cancel</button>
            </template>
            <template v-else>
              <button v-if="item.status == 'Disabled'" class="second" v-on:click="deleteItem(item.id)">Remove</button>
              <button @click="edit(index)" class="button-handle update">Update</button>
            </template>
          </td>
        </tr>
      </table>
    </section>
    <section class="popup" id="providerPopup">
      <div class="pop-inner">
        <div class="pop-box">
          <a class="close"></a>
          <h2>{{popupItem.provider}}</h2>
          <div class="pf-row split">
            <div class="pf-field">
              <label>{{popupItem.system}}</label>
            </div>
          </div>
          <div class="pf-row split">
            <div class="pf-field">
              <label>{{popupItem.connections}}</label>
            </div>
          </div>
          <div class="pf-row buttons">
            <a href="" class="pop-btn close-popup">Cancel</a>
          </div>
        </div>
      </div>
    </section>
    <section class="popup" id="statusPopup">
      <div class="pop-inner">
        <div class="pop-box">
          <a class="close"></a>
          <h2>Update Status</h2>
          <div class="pf-row split">
            <div class="pf-field">
              <select v-model="status">
                <option value="Review">Review</option>
                <option value="Testing">Testing</option>
                <option value="Active">Active</option>
                <option value="Disabled">Disabled</option>
              </select>
            </div>
          </div>
          <div class="pf-row buttons">
            <span class="pop-btn" v-on:click="updateStatus()">Confirm</span>
            <a href="" class="pop-btn close-popup">Cancel</a>
          </div>
        </div>
      </div>
    </section>
    <popup :open.sync="add">
      <h2>Add New Integration</h2>
      <form class="pop-form" v-on:submit.prevent="create()">
        <h3>INTEGRATION INFORMATION</h3>
        <div class="pf-row split">
          <div class="pf-field">
            <label>System</label>
            <select v-model="newItem.system" @change="changeSystem($event)" required :disabled="editStatus">
              <option value="">-</option>
              <option v-bind:value="system.name" v-bind:data-index="index" v-for="(system, index) in systems">
                {{ system.name }}
              </option>
            </select>
          </div>
          <div class="pf-field">
            <label>System Version</label>
            <input type="text" v-model="newItem.systemVersion" required  :disabled="editStatus"/>
          </div>
        </div>
        <div class="pf-row split">
          <div class="pf-field">
            <label>Company Name</label>
            <input type="text" v-model="newItem.companyName" required  :disabled="editStatus"/>
          </div>
        </div>
        <div class="pf-row split">
          <div class="pf-field">
            <label>Provider</label>
            <select v-model="newItem.providerShortname" @change="changeProvider($event)" required  :disabled="editStatus">
              <option value="">-</option>
              <option v-bind:value="provider.shortname" v-bind:data-index="index"
                      v-for="(provider, index) in providers">
                {{ provider.shortname }}
              </option>
            </select>
          </div>
          <div class="pf-field">
            <label>Provider Version</label>
            <input type="text" v-model="newItem.providerVersion" required  :disabled="editStatus"/>
          </div>
        </div>
        <div class="pf-row split">
          <div class="pf-field">
            <label>Provider Name</label>
            <input type="text" v-model="newItem.provider" required  :disabled="editStatus"/>
          </div>
        </div>
        <div class="pf-row">
          <div class="pf-field">
            <label>Connections</label>
            <input type="text"  v-model="newItem.connections" required :disabled="editStatus"/>
          </div>
        </div>
        <div class="pf-row">
          <div class="pf-field">
            <label>Notes</label>
            <input type="text"  v-model="newItem.notes" required :disabled="editStatus"/>
          </div>
        </div>
        <div class="pf-row">
          <div class="pf-field">
            <label>Certification Date</label>
            {{newItem.certDate | date('MMMM D YYYY')}}
          </div>
        </div>
        <div class="pf-row buttons">
          <a class="pop-btn close-popup" @click="add = false">Cancel</a>
          <a v-if="editStatus" class="edit pop-btn" @click="editStatus = false">Edit</a>
          <button v-else class="pop-btn">Save</button>
        </div>
      </form>
    </popup>
  </div>
</template>

<script>
  import VueEnglishdatepicker from 'vue-englishdatepicker';
  export default {
    components: {
      VueEnglishdatepicker,
    },

    data: () => ({
      editing: [],
      status: '',
      statusId: '',
      items: [],
      systems: [],
      providers: [],
      newItem: {
        system: "",
        systemVersion: "",
        provider: "",
        providerVersion: "",
        connections: "",
        notes: "",
        username: "",
        password: "",
        keey: ""
      },
      popupItem: {
        system: "",
        provider: "",
        connections: "",
      },
      add: false,
      editStatus: false,

      appliedFilters: null,

      form: {
        name: null,
        provider: null
      },
    }),

    computed: {
      data () {
        let data = this.items || []

        if (!this.appliedFilters) return data

        if (this.appliedFilters.name) {
          data = data.filter(item => item.system == this.appliedFilters.name)
        }

        if (this.appliedFilters.provider) {
          data = data.filter(item => item.providerShortname == this.appliedFilters.provider)
        }

        return data
      },
    },

    methods: {
      search () {
        this.appliedFilters = { ...this.form }
      },

      resetItem() {
        this.appliedFilters = null
        Object.keys(this.form).forEach(key => this.form[key] = null)
      },

      async reload() {
        this.items = (await api.list("SystemIntegrations", {}, ["id:ASC"])).items;
        this.systems = (await api.list("SystemSettings", {}, ["id:ASC"])).items;
        this.providers = (await api.list("Provider", {}, ["id:ASC"])).items;
      },
      async createItem() {
        await api.create("SystemIntegrations", this.newItem, true);
        this.reload();
        this.newItem = {
          system: "",
          systemVersion: "",
          provider: "",
          providerVersion: "",
          connections: "",
          notes: "",
          username: "",
          password: "",
          keey: ""
        }
      },

      async create() {
        if(this.newItem.id){
          await api.update("SystemIntegrations", this.newItem.id, this.newItem, true);
        }else{
          this.newItem.status = 'Review'
          await api.create("SystemIntegrations", this.newItem, true);
        }
        this.reload();
        this.add = false
        this.newItem = {
          system: "",
          systemVersion: "",
          companyName: "",
          provider: "",
          providerVersion: "",
          providerShortname: "",
          connections: "",
          notes: "",
          username: "",
          password: "",
          keey: ""
        }
      },

      async deleteItem(id) {
        var r = confirm("Are you sure?");
        if (r == true) {
          await api.delete("SystemIntegrations", id);
          this.reload();
        } else {
          return;
        }
      },

      changeSystem(e) {
        if (e.target.options.selectedIndex > -1) {
          const theTarget = e.target.options[e.target.options.selectedIndex].dataset;
          this.newItem.systemVersion = this.systems[theTarget.index].version;
          this.newItem.companyName = this.systems[theTarget.index].organization.name;
        }
      },
      changeProvider(e) {
        if (e.target.options.selectedIndex > -1) {
          const theTarget = e.target.options[e.target.options.selectedIndex].dataset;
          this.newItem.providerVersion = this.providers[theTarget.index].version;
          this.newItem.provider = this.providers[theTarget.index].provider;
        }
      },
      popupProvider(id) {
        this.popupItem.provider = this.items[id].provider;
        this.popupItem.system = this.items[id].system;
        this.popupItem.connections = this.items[id].connections;
        $("#providerPopup").toggleClass("open");
      },

      async popupIntegration(item) {
        this.newItem = item
        this.add = true
        this.editStatus = true
      },

      async openUpdateStatus(id, status) {
        this.statusId = id;
        this.status = status;
        $("#statusPopup").toggleClass("open");
      },

      async updateStatus() {
        await api.update("SystemIntegrations", this.statusId, {"status":this.status}, true);
        $("#statusPopup").toggleClass("open");
        this.reload();
      },

      async saveUpdate (i) {
        try {
          const payload = {
            name: this.items[i].name,
            systemVersion: this.items[i].systemVersion,
            provider: this.items[i].provider,
            providerVersion: this.items[i].providerVersion,
            connections: this.items[i].connections,
            notes: this.items[i].notes,
            certDate: this.items[i].certDate,
          }
          await api.update("SystemIntegrations", this.items[i].id, payload, true);
          this.cancelEdit(i)
        } catch (e) {
        }
      },

      cancelEdit (i) {
        const index = this.editing.indexOf(i)
        this.editing.splice(index, 1)
      },

      isEdit (index) {
        return this.editing.includes(index)
      },

      edit(index) {
        this.editing.push(index)
      },
    },
    mounted() {
      this.reload();
    },
  }
</script>
<style lang="scss" scoped>
  h3 {
    color: #0275be;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;
    border-bottom: 1px solid #99aabb;
  }

  .pop-form button.main {
    background: #0480c6;
    color: white;
    min-width: 120px;
    border: 0;
    box-sizing: border-box;
    height: 29px;
    border-radius: 15px;
    font-size: 12px;
    font-weight: bold;
  }
  .edit.pop-btn {
    margin-left: 16px;
    background: #0480c6 !important;
    border-color: transparent;
    color: white !important;
  }
</style>