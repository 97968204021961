<template>
  <div>
    <section class="content-filters">
      <div class="cof-box">
        <a href="" class="tsm-handle">Filters</a>
        <!--   <h4>Transaction Search</h4> -->
        <div class="cof-form">
          <a class="cof-close"></a>
          <!--    <h6>Transaction Search</h6> -->
          <div class="cof-row">
            <div class="cof-field">
              <label>Date / Time</label>
              <div class="cof-multi flex">
                <vue-englishdatepicker v-model="newItem.fromDate" classValue="fromDate"/>
                <span>To</span>
                <vue-englishdatepicker v-model="newItem.toDate" classValue="toDate" />
              </div>
            </div>
            <div class="cof-field" style="width:90px">
              <label>APIX ID</label>
              <input type="text" v-model="newItem.apixId"/>
            </div>
            <div class="cof-field" style="width:90px">
              <label>System</label>
              <select v-model="newItem.system" @change="changeSystem($event)">
                <option value="">-</option>
                <option v-bind:value="system.name" v-bind:data-index="index" v-for="(system, index) in systems">
                  {{ system.name }}
                </option>
              </select>
            </div>
            <div class="cof-field" style="width:90px">
              <label>Version</label>
              <input type="text" v-model="newItem.systemVersion"/>
            </div>
            <div class="cof-field">
              <label>Provider</label>
              <select v-model="newItem.provider" @change="changeProvider($event)">
                <option value="">-</option>
                <option v-bind:value="provider.provider" v-bind:data-index="index"
                        v-for="(provider, index) in providers">
                  {{ provider.provider }}
                </option>
              </select>
            </div>
            <div class="cof-field" style="width: 90px;">
              <label>Version</label>
              <input type="text" v-model="newItem.providerVersion"/>
            </div>
            <div class="cof-field">
              <label>Status</label>
              <select v-model="newItem.status">
                <option value="success">Success</option>
                <option value="failed">Failed</option>
                <option value="error">Error</option>
              </select>
            </div>
          </div>
          <div class="cof-row">
            <div class="cof-field mobinl">
              <label>&nbsp;</label>
              <button class="main" v-on:click="filterItem()">Filter</button>
            </div>
            <div class="cof-field mobinl">
              <label>&nbsp;</label>
              <button class="second" v-on:click="resetItem()">Reset Fields</button>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section  class="content-tlist">
      <table class="content-table">
        <tr>
          <th style="width: 30px;"></th>
          <th>Date / Time</th>
          <th>APIX ID</th>
          <th>System</th>
          <th>Version</th>
          <th>Provider</th>
          <th>Version</th>
          <th>Status</th>
          <th>Options</th>
        </tr>
        <tr v-for="(item, index) in displayedPosts">
          <td><span></span>{{ index+1 }}</td>
          <td><span>Date / Time</span>{{item.callTime}}</td>
          <td><span>APIX ID</span>{{item.apixId}}</td>
          <td><span>System</span>{{item.system}}</td>
          <td><span>Version</span>{{item.systemVersion}}</td>
          <td><span>Provider</span>{{item.provider}}</td>
          <td><span>Version</span>{{item.providerVersion}}</td>
          <td><span>Status</span><em v-if="item.status === 'success'">Success</em><em v-if="item.status === 'failed'" class="c2">Failed</em></td>
          <td><span>Options</span><a v-on:click="popupProvider(index)">View</a></td>
        </tr>
      </table>

    </section>
    <section class="pagination-section">
      <div>
        Per page
        <select v-model="perPage" @change="setPages()">
          <option value="25">25</option>
          <option value="50">50</option>
          <option value="100">100</option>
          <option value="250">250</option>
        </select>
      </div>
      <nav aria-label="Page navigation example">
        <ul class="pagination">
          <li class="page-item">
            <button type="button" class="page-link" v-if="page != 1" @click="page--"> Previous </button>
          </li>
          <li class="page-item">
            <button type="button" class="page-link" v-for="pageNumber in pages.slice(page-1, page+5)" @click="page = pageNumber"> {{pageNumber}} </button>
          </li>
          <li class="page-item">
            <button type="button" @click="page++" v-if="page < pages.length" class="page-link"> Next </button>
          </li>
        </ul>
      </nav>
    </section>
    <section class="popup" id="providerPopup">
      <div class="pop-inner">
        <div class="pop-box">
          <a class="close"></a>
          <h2>{{popupItem.callTime}}</h2>
          <div class="pf-row">
            API REQUEST
            <div class="api-pane">
              <label>{{popupItem.request}}</label>
            </div>
          </div>
          <div class="pf-row">
            APIX RESPONSE
            <div class="api-pane">
              <label>{{popupItem.apixResponse}}</label>
            </div>
          </div>
          <div class="pf-row">
            PROVIDER RESPONSE
            <div class="api-pane">
              <label>{{popupItem.providerResponse}}</label>
            </div>
          </div>
          <div class="pf-row buttons">
            <a href="" class="pop-btn close-popup">Cancel</a>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
  import VueEnglishdatepicker from 'vue-englishdatepicker';

  export default {

    components: {
      VueEnglishdatepicker,
    },

    data: () => ({
      editing: {
        status: false,
        index: 0
      },
      items: [],
      systems: [],
      providers: [],
      newItem: {
        system: "",
        systemVersion: "",
        provider: "",
        providerVersion: "",
        fromDate: "",
        toDate: "",
        status: "",
        apixId: "",
      },
      popupItem: {
        callTime: "",
        request: "",
        apixResponse: "",
        providerResponse: "",
      },
      posts : [''],
      page: 1,
      perPage: 25,
      pages: [],
      filter: {
        system: null,
        systemVersion: null,
        provider: null,
        providerVersion: null,
        fromDate: null,
        toDate: null,
        status: null,
        apixId: null,
      }
    }),
    methods: {
      async reload() {
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();

        today = yyyy + '/' + mm + '/' + dd;
        this.items = (await api.list("ApiCalls", { fromDate: ['>=', today + ' 00:00:00', 'callTime'], toDate: ['<=', today + ' 23:59:59', 'callTime']}, ["id:DESC"])).items;
        this.posts = this.items;
        this.systems = (await api.list("SystemSettings", {}, ["id:ASC"])).items;
        this.providers = (await api.list("Provider", {}, ["id:ASC"])).items;
      },

      async filterItem() {
        if(this.newItem.system == ''){
          delete this.filter.system;
        }else{
          this.filter.system = this.newItem.system;
        }
        if(this.newItem.systemVersion == ''){
          delete this.filter.systemVersion;
        }else{
          this.filter.systemVersion = this.newItem.systemVersion;
        }
        if(this.newItem.provider == ''){
          delete this.filter.provider;
        }else{
          this.filter.provider = this.newItem.provider;
        }
        if(this.newItem.providerVersion == ''){
          delete this.filter.providerVersion;
        }else{
          this.filter.providerVersion = this.newItem.providerVersion;
        }
        if(this.newItem.fromDate == ''){
          delete this.filter.fromDate;
        }else{
          this.filter.fromDate = null;
          this.filter.fromDate = ['>=', this.newItem.fromDate + ' 00:00:00', 'callTime']
        }
        if(this.newItem.toDate == ''){
          delete this.filter.toDate;
        }else{
          this.filter.toDate = null;
          this.filter.toDate = ['<=', this.newItem.toDate + ' 23:59:59', 'callTime']
        }
        if(this.newItem.status == ''){
          delete this.filter.status;
        }else{
          this.filter.status = this.newItem.status;
        }
        if(this.newItem.apixId == ''){
          delete this.filter.apixId;
        }else{
          this.filter.apixId = this.newItem.apixId;
        }

        this.items = (await api.list("ApiCalls", this.filter, ["id:DESC"])).items;
        this.posts = this.items;

      },

      async resetItem() {
        this.newItem = {
          system: "",
            systemVersion: "",
            provider: "",
            providerVersion: "",
            fromDate: "",
            toDate: "",
            status: "",
            apixId: "",
        }
        $('.fromDate').val('');
        $('.toDate').val('');
        this.reload();
      },

      async deleteItem(id) {
        var r = confirm("Are you sure?");
        if (r == true) {
          await api.delete("SystemIntegrations", id);
          this.reload();
        } else {
          return;
        }
      },

      changeSystem(e) {
        if (e.target.options.selectedIndex > -1) {
          const theTarget = e.target.options[e.target.options.selectedIndex].dataset;
          this.newItem.systemVersion = this.systems[theTarget.index].version;
        }
      },
      changeProvider(e) {
        if (e.target.options.selectedIndex > -1) {
          const theTarget = e.target.options[e.target.options.selectedIndex].dataset;
          this.newItem.providerVersion = this.providers[theTarget.index].version;
        }
      },
      popupProvider(id) {
        this.popupItem.callTime = this.items[id].callTime;
        this.popupItem.request = this.items[id].request;
        this.popupItem.apixResponse = this.items[id].apixResponse;
        this.popupItem.providerResponse = this.items[id].providerResponse;
        $(".popup").toggleClass("open");
      },

      setPages () {
        let numberOfPages = Math.ceil(this.posts.length / this.perPage);
        this.pages = [];
        for (let index = 1; index <= numberOfPages; index++) {
          this.pages.push(index);
        }
      },
      paginate (posts) {
        let page = this.page;
        let perPage = this.perPage;
        let from = (page * perPage) - perPage;
        let to = (page * perPage);
        return  posts.slice(from, to);
      },
      getPosts () {
        let data = [];
        this.posts = this.items;
        console.log(this.posts);
      },
    },

    computed: {
      displayedPosts () {
        return this.paginate(this.posts);
      }
    },
    watch: {
      posts () {
        this.setPages();
      }
    },
    created(){
      this.getPosts();
    },
    filters: {
      trimWords(value){
        return value.split(" ").splice(0,20).join(" ") + '...';
      }
    },

    mounted() {
      this.reload();
    },
  }
</script>
