import $ from 'jquery';
import moment from 'moment';
import Vue from 'vue';

window.startLoading=function(steps) {
  window.loadingSteps=steps;
  $(".loading").addClass("visible");
  if (steps)
    $(".loading").addClass("progress");
  else
    $(".loading").removeClass("progress");
  $(".loading .progress .step").html(1);
  $(".loading .progress .steps").html(steps);
  $(".loading .progress .bar").css("width", "0%").css("transition", "0s");
}

window.progressLoading=function(step) {
  $(".loading .progress .step").html(step+1);
  $(".loading .progress .bar").css("width", step*100/window.loadingSteps+"%").css("transition", ".3s");
}

window.stopLoading=function(message, status) {
  $(".loading").removeClass("visible");
  if (message)
    window.appNotifications.show(message, status || "success");
}

window.validateFileExt=function(file) {
  var whitelistedExtensions=[".jpg", ".jpeg", ".gif", ".tif", ".tiff", ".pdf", ".png"];
  if (!file || !file.name)
    return false;
  var matches=file.name.match(/\.[^.]+$/);
  if (!matches)
    return false;
  var ext=matches[0].toLowerCase();
  return whitelistedExtensions.indexOf(ext)!=-1;
}

window.copyTextToClipboard=function(text) {
  var textArea=document.createElement("textarea");
  textArea.style.position="fixed";
  textArea.style.left="-1000px";
  
  textArea.value=text;
  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();
  var successful=false;
  try {
    successful=document.execCommand('copy');
  } catch (err) {
  }
  document.body.removeChild(textArea);
  return successful;
}



window.serverDiff=Math.round((moment().unix()-moment(serverNow).unix())/60)*60;
window.vueMixins = window.vueMixins || {}

window.vueMixins.helpers = {
  computed: {
    currencies () {
      return [
        { value: "124", label: "CAD" },
        { value: "840", label: "USD" },
        // {value: "036", label: "AUD"},
        // {value: "986", label: "BRL"},
        // {value: "756", label: "CHF"},
        // {value: "978", label: "EUR"},
        // {value: "826", label: "GBP"},
        // {value: "344", label: "HKD"},
        // {value: "392", label: "JPY"},
        // {value: "554", label: "NZD"},
      ]
    }
  },
  methods: {
    changeFileName (file, name) {
      const ext = file.name.substr(file.name.lastIndexOf('.') + 1)
      const blob = file.slice(0, file.size, file.type);
      const fileName = name + '.' + ext
      return new File([blob], fileName, { type: file.type })
    },
    formatDate(source, format) {
      if (!source)
        return "";
      return moment(source).format(format);
    },
    agoTimer(source, now) {
      if (typeof(now)=="number")
        now=undefined;
      var t=moment(source);
      var n=moment(now).subtract(serverDiff, "s");
      if (n.unix()-t.unix()<0)
        return "00:00:00";
      var diff=n.unix()-t.unix();
      var h=Math.floor(diff/3600);
      var m=Math.floor(diff/60)%60;
      var s=Math.floor(diff)%60;
      return (h<10?"0":"")+h+":"+(m<10?"0":"")+m+":"+(s<10?"0":"")+s;
    },
  }
}


$(function($) {
  window.vueMixins=window.vueMixins || {}
  $("[vue-dyn]").each(function() {
    var $this=$(this);
    var mixins=$this.attr("vue-dyn").split(",").filter(function(a) {
      return a;
    }).map(function(a) {
      return window.vueMixins[a];
    });
    $this.removeAttr("vue-dyn");
    (new Vue({
      el: this,
      mixins: mixins
    }));
  });
});
