var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "popup", class: { open: _vm.open } }, [
    _c(
      "div",
      { staticClass: "pop-inner", style: { maxWidth: _vm.width + "px" } },
      [
        _c(
          "div",
          { staticClass: "pop-box" },
          [
            _c("a", {
              staticClass: "close",
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.$emit("update:open", false)
                }
              }
            }),
            _vm._v(" "),
            _vm._t("default")
          ],
          2
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }