<template>
  <div>
    <section class="content-filters">
      <div class="cof-box">
        <a href="" class="tsm-handle">Filters</a>
        <!--   <h4>Transaction Search</h4> -->
        <div class="cof-form">
          <a class="cof-close"></a>
          <!--    <h6>Transaction Search</h6> -->
          <form v-on:submit.prevent="createItem()">
            <div class="cof-row">
              <div class="cof-field">
                <label>Other System Name</label>
                <input type="text" v-model="newItem.systemName" required/>
              </div>
              <div class="cof-field" style="width:90px">
                <label>Version</label>
                <input type="text" v-model="newItem.systemVersion" required/>
              </div>
              <div class="cof-field">
                <label>Provider</label>
                <select v-model="newItem.provider" @change="changeProvider($event)" required>
                  <option value="">-</option>
                  <option v-bind:value="provider.provider" v-bind:data-index="index"
                          v-for="(provider, index) in providers">
                    {{ provider.provider }}
                  </option>
                </select>
              </div>
              <div class="cof-field" style="width:90px">
                <label>Version</label>
                <input type="text" v-model="newItem.providerVersion" required/>
              </div>
              <div class="cof-field" style="width:300px">
                <label>Connections</label>
                <input type="text" v-model="newItem.connections" required/>
              </div>
              <div class="cof-field" style="width: 650px;">
                <label>Notes</label>
                <input type="text" v-model="newItem.notes"/>
              </div>
            </div>
            <div class="cof-row">
              <div class="cof-field">
                <label>Username</label>
                <input type="text" v-model="newItem.username"/>
              </div>
              <div class="cof-field">
                <label>Password</label>
                <input type="text" v-model="newItem.password"/>
              </div>
              <div class="cof-field">
                <label>Key</label>
                <input type="text" v-model="newItem.keey"/>
              </div>
              <div class="cof-field mobinl">
                <label>&nbsp;</label>
                <button class="main">Add System</button>
              </div>
              <div class="cof-field mobinl">
                <label>&nbsp;</label>
                <button class="second">Reset Fields</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
    <section class="content-tlist">
      <table class="content-table">
        <tr>
          <th>Other System Name</th>
          <th>Version</th>
          <th>Provider</th>
          <th>Version</th>
          <th>Certified Date</th>
          <th>Connections</th>
          <th>Status</th>
          <th>Notes</th>
          <th>Options</th>
          <th>Username</th>
          <th>Password</th>
          <th>Key</th>
        </tr>
        <tr v-for="(item, index) in items">
          <td><span>Other System Name</span>{{item.systemName}}</td>
          <td><span>Version</span>{{item.systemVersion}}</td>
          <td><span>Provider</span><a href="">{{item.provider}}</a></td>
          <td><span>Version</span>{{item.providerVersion}}</td>
          <td><span>Certified Date</span>{{item.certDate}}</td>
          <td><span>Connections</span>{{item.connections}}</td>
          <td><span>Status</span><em class="c2">Review</em></td>
          <td><span>Notes</span>{{item.notes}}</td>
          <td><span>Options</span>
            <button class="second" v-on:click="deleteItem(item.id)">Remove</button>
          </td>
          <td><span>Username</span>{{item.username}}</td>
          <td><span>Password</span>{{item.password}}</td>
          <td><span>Key</span>{{item.keey}}</td>
        </tr>
      </table>
    </section>
  </div>
</template>

<script>
  export default {
    data: () => ({
      editing: {
        status: false,
        index: 0
      },
      items: [],
      systems: [],
      providers: [],
      newItem: {
        systemName: "",
        systemVersion: "",
        provider: "",
        providerVersion: "",
        connections: "",
        notes: "",
        username: "",
        password: "",
        keey: ""
      },
    }),
    methods: {
      async reload() {
        this.items = (await api.list("OtherIntegrations", {}, ["id:ASC"])).items;
        this.providers = (await api.list("Provider", {}, ["id:ASC"])).items;
      },
      async createItem() {
        await api.create("OtherIntegrations", this.newItem, true);
        this.reload();
        this.newItem = {
          systemName: "",
          systemVersion: "",
          provider: "",
          providerVersion: "",
          connections: "",
          notes: "",
          username: "",
          password: "",
          keey: ""
        }
      },

      async deleteItem(id) {
        var r = confirm("Are you sure?");
        if (r == true) {
          await api.delete("OtherIntegrations", id);
          this.reload();
        } else {
          return;
        }

      },

      changeProvider(e) {
        if (e.target.options.selectedIndex > -1) {
          const theTarget = e.target.options[e.target.options.selectedIndex].dataset;
          this.newItem.providerVersion = this.providers[theTarget.index].version;
        }
      },
    },
    mounted() {
      this.reload();
    },
  }
</script>
