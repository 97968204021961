<template>
<div class="status-page">
  <div class="ss-head">
    <div>
      <h1>System Status</h1>
      <div class="next-schedule" style="">
        <label style="">Scheduled maintenance or downtime:</label>
        <p>{{ scheduled || "no downtime scheduled" }}</p>
      </div>
    </div>
  </div>
  <div class="status-list">
    <ul>
      <li v-for="(item, index) in items" :class="{opened: opened[index]}">
        <div v-on:click.prevent="toggleOpened(index)">
          <a class="toggle" href="">
            <!--{{ opened[index]?'-':'+' }}-->
            {{ item.title }}
          </a>
        </div>
        <ul v-if="opened[index]">
          <li v-for="(subitem, si) in item.items">
            <div>{{ subitem.name }}</div>
            <div>{{ subitem.downMsg }}</div>
            <div class="color" :class="subitem.color"></div>
            <div class="err" v-if="subitem.downTime">{{ subitem.downTime }}</div>
            <div v-else>Operational</div>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</div>
</template>

<script>
export default {
  data() { return {
    items: appData.status.items,
    scheduled: appData.status.scheduled,
    opened: appData.status.items.map((a)=>false),
  }},
  methods: {
    toggleOpened(index) {
      this.$set(this.opened, index, !this.opened[index]);
    },
  },
}
</script>
