<template>
  <div>
    <section class="content-filters">
      <basic-form>
        <div class="cof-row">
          <div class="cof-field">
            <mnb-input
              v-model="form.version"
              label="Version" />
            <span v-if="$v.form.version.$dirty && $v.form.version.$invalid" class="error">This field is required !</span>
          </div>
          <div class="full-w">
            <mnb-textarea
              v-model="form.description"
              label="Description"
              @input="mixin_autoResize_resize"
              :classes="{ 'full': true, 'noResize': true }"
              full/>
            <span v-if="$v.form.description.$dirty && $v.form.description.$invalid" class="error">This field is required !</span>
          </div>
        </div>
        <div class="cof-row">
          <div class="cof-field mobinl">
            <label>&nbsp;</label>
            <button class="main" @click="save()">Add Change Log</button>
          </div>
          <div class="cof-field mobinl">
            <label>&nbsp;</label>
            <button class="second" @click="resetField()">Reset Fields</button>
          </div>
        </div>
      </basic-form>
    </section>

    <section  class="content-tlist">
      <table class="content-table">
        <tr>
          <th style="width: 20%">Date</th>
          <th style="width: 20%">Version</th>
          <th style="width: 40%">Description</th>
          <th style="width: 20%">Options</th>
        </tr>
        <tr v-for="(item, i) in logsSorted" :key="i">
          <td><span>Date</span>{{ item.createdAt | date('dddd, MMMM D YYYY') }}</td>
          <td>
            <span>Version</span>

            <template v-if="!isEdit(i)">{{ item.version }}</template>
            <mnb-input
              v-else
              :ref="`input_${i}`"
              v-model="editingVersion[i]"/>
          </td>
          <td style="width: 500px; padding-right: 10px;">
            <span>Description</span>
            <template v-if="!isEdit(i)"> {{ item.description }}</template>
            <mnb-textarea
              v-else
              v-model="editingText[i]"
              :classes="{ 'noResize': true}"
              @input="mixin_autoResize_resize"
              full/>
          </td>
          <td>
            <span>Options</span>
            <template v-if="isEdit(i)">
              <button @click="saveUpdate(i)" class="message-handle save">Save</button>
              <button @click="cancelEdit(i)" class="message-handle cancel">Cancel</button>
            </template>
            <template v-else>
              <button @click="editLog(i)" class="message-handle update">Update</button>
              <button @click="deleteLog(item.id)" class="message-handle remove">Remove</button>
            </template>
          </td>
        </tr>
      </table>
    </section>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import BasicForm from './components/ui/forms/Form'
import MnbInput from './components/ui/forms/Input'
import MnbTextarea from './components/ui/forms/Textarea'

import mixinAutoResize from "./components/ui/forms/autoresize.js"
import inputMixin from "./components/ui/forms/input-mixin.js"

export default {
  name: 'system-change-logs',

  mixins: [mixinAutoResize],

  components: {
    BasicForm,
    MnbInput,
    MnbTextarea
  },

  data() {
    return {
      form: {
        version: null,
        description: null
      },
      editing: [],
      editingText: {},
      editingVersion: {},
      logs: []
    }
  },


  computed: {
    logsSorted () {
      return this.logs.sort((a, b) => {
        if (a.id > b.id) return -1
        if (a.id < b.id) return 1
        return 0
      })
    }
  },

  async created () {
    this.reload()
  },

  methods: {
    async reload() {
      this.logs = (await api.list("ChangeLog", {}, ["id:ASC"])).items;
    },

    isEdit (index) {
      return this.editing.includes(index)
    },

    async save () {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        const loader = this.$loading.show()
        try {
          const payload = {
            version: this.form.version,
            description: this.form.description,
            createdAt: 'CURRENT_TIMESTAMP'
          }

          await api.create("ChangeLog", payload, true)
          this.form.version = null
          this.form.description = null
          this.reload()
          this.$v.$reset()
        } catch (e) {
        } finally {
          loader.hide()
        }
      }
    },

    resetField() {
      this.form.version = null
      this.form.description = null
    },

    async deleteLog(id) {
      if(confirm(`Are you sure to delete Change Log?`)) {
        const loader = this.$loading.show()
        await api.delete("ChangeLog", id)
        this.reload()
        loader.hide()
      }
    },

    async saveUpdate (i) {
      if (this.editingText[i]) {
        const loader = this.$loading.show()
        try {
          const payload = {
              version: this.editingVersion[i],
              description: this.editingText[i]
          }
          await api.update("ChangeLog",this.logs[i].id, payload, true)
          this.cancelEdit(i)
          this.reload()
        } catch (e) {
          this.$toast.error(window.errorMessage)
        } finally {
          loader.hide()
        }
      }
    },

    cancelEdit (i) {
      const index = this.editing.indexOf(i)
      this.editing.splice(index, 1)
      delete this.editingText[index]
      delete this.editingVersion[index]
    },

    editLog(index) {
      this.editing.push(index)
      this.editingText[index] = this.logs[index].description
      this.editingVersion[index] = this.logs[index].version
      this.$nextTick(() => this.$refs[`input_${index}`][0].$el.getElementsByTagName('input')[0].focus())
    }
  },

  validations: {
    form: {
      version: { required },
      description: { required }
    }
  }
}
</script>

<style lang="scss" scoped>
  .second {
    margin-left: 70px;
  }
  .error {
    display: block;
    margin-top: 5px;
    color: #d23131;
  }
  .message-handle {
    display: inline-block;
    padding: 0 10px;
    line-height: 26px;
    vertical-align: middle;
    border-radius: 4px;
    min-width: 70px;
    text-align: center;
    color: white;
    border: none;
    outline: none;

    &.update {
      background-color: #ffa700;
    }
    &.remove {
      background-color: #d62b2b;
    }
    &.save {
      background-color:#67c23a;
    }
    &.cancel {
      background-color: #d62b2b;
    }
  }
  .noUpdate {
    background-color: transparent;
    border: none;
  }
</style>