<template>
  <div>
    <section>
      <em class="pop-show add-emp" @click="newSystem()">+ New System</em>
    </section>
    <section class="content-filters">
      <div class="cof-box">
        <a href="" class="tsm-handle">Filters</a>
        <!--   <h4>Transaction Search</h4> -->
        <div class="cof-form">
          <a class="cof-close"></a>
          <!--    <h6>Transaction Search</h6> -->
            <div class="cof-row">
              <div class="cof-field">
                <label>Organization</label>
                <select v-model="form.organization">
                  <option value="">-</option>
                  <option v-if="organization.name" v-bind:value="organization.name" v-bind:data-index="index"
                          v-for="(organization, index) in organizations">
                    {{ organization.name }}
                  </option>
                </select>
              </div>
              <div class="cof-field">
                <label>System Name</label>
                <select v-model="form.name">
                  <option value="">-</option>
                  <option v-bind:value="item.name" v-bind:data-index="index"
                          v-for="(item, index) in items">
                    {{ item.name }}
                  </option>
                </select>
              </div>
              <div class="cof-field">
                <label>Username</label>
                <input type="text" v-model="form.username"/>
              </div>
              <div class="cof-field mobinl">
                <label>&nbsp;</label>
                <button class="main" @click="search()">Filter</button>
              </div>
              <div class="cof-field mobinl">
                <label>&nbsp;</label>
                <button class="second" @click="resetItem()">Reset Fields</button>
              </div>
            </div>
        </div>
      </div>
    </section>
    <section class="content-tlist">
      <table class="content-table">
        <tr>
          <th>Organization</th>
          <th>System Name</th>
          <th>Version</th>
          <th>Category</th>
          <th>Status</th>
          <th>Certified Date</th>
          <th>Username</th>
          <th>Key</th>
          <th>Callback URL</th>
          <th style="text-align: right;">Options</th>
        </tr>
        <tr v-for="(item, index) in data">
          <td><span>Organization</span>
            <a v-on:click="popupOrg(item)">{{item.organization? item.organization.name: ''}}</a>
          </td>
          <td><span>System Name</span>
            <template v-if="!isEdit(index)"><a v-on:click="popupSystem(item)">{{item.name}}</a></template>
            <input type="text" v-else v-model="item.name" />
          </td>
          <td><span>Version</span>
            <template v-if="!isEdit(index)">{{item.version}}</template>
            <input type="text" v-else v-model="item.version" />
          </td>
          <td><span>Category</span>
            <template v-if="!isEdit(index)">{{item.category}}</template>
            <select v-else v-model="item.category">
              <option value="Payments">Payments</option>
              <option value="Lending">Lending</option>
              <option value="Risk & Compliance">Risk & Compliance</option>
              <option value="Portal">Portal</option>
              <option value="Billing & Invoicing">Billing & Invoicing</option>
              <option value="Back-Office">Back-Office</option>
              <option value="Software">Software</option>
            </select>
          </td>
          <td v-on:click="openUpdateStatus(item.id, item.status)"><span>Status</span>
           <em v-if="item.status === 'Active'">Active</em><em v-if="item.status !== 'Active'" class="c2">{{item.status}}</em>
          </td>
          <td><span>Certified Date</span>
            <template v-if="!isEdit(index)">{{item.certDate}}</template>
            <vue-englishdatepicker v-else v-model="item.certDate"/>
          </td>
          <td><span>Username</span>
            <template v-if="!isEdit(index)">{{item.username}}</template>
            <input type="text" v-else v-model="item.username" />
          </td>
          <td><span>Key</span>
            <template v-if="!isEdit(index)">{{item.keey}}</template>
            <input type="text" v-else v-model="item.keey" />
          </td>
          <td><span>Callback URL</span>
            <template v-if="!isEdit(index)">{{item.callbackUrl}}</template>
            <input type="text" v-else v-model="item.callbackUrl" />
          </td>
          <td style="text-align: right;"><span>Options</span>
            <template v-if="isEdit(index)">
              <button @click="saveUpdate(index)" class="button-handle save">Save</button>
              <button @click="cancelEdit(index)" class="button-handle cancel">Cancel</button>
            </template>
            <template v-else>
              <button v-if="item.status == 'Disabled'" class="second" v-on:click="deleteItem(item)">Remove</button>
              <button @click="edit(index)" class="button-handle update">Update</button>
            </template>
          </td>
        </tr>
      </table>
    </section>
    <section class="popup" id="statusPopup">
      <div class="pop-inner">
        <div class="pop-box">
          <a class="close"></a>
          <h2>Update Status</h2>
          <div class="pf-row split">
            <div class="pf-field">
              <select v-model="status">
                <option value="Review">Review</option>
                <option value="Testing">Testing</option>
                <option value="Active">Active</option>
                <option value="Disabled">Disabled</option>
              </select>
            </div>
          </div>
          <div class="pf-row buttons">
            <span class="pop-btn" v-on:click="updateStatus()">Confirm</span>
            <a href="" class="pop-btn close-popup">Cancel</a>
          </div>
        </div>
      </div>
    </section>
    <popup :open.sync="addSystem">
      <h2>Add New System</h2>
      <form class="pop-form" v-on:submit.prevent="createSystem()">
        <h3>ORGANIZATION INFORMATION</h3>
        <div class="pf-row split">
          <div class="pf-field">
            <label>Organization Type</label>
            <select v-model="newItem.orgType" required :disabled="editStatus">
              <option value="Acquirer">Acquirer</option>
              <option value="Developer">Developer</option>
              <option value="Enterprise Merchant">Enterprise Merchant</option>
              <option value="Financial">Financial</option>
              <option value="Fintech">Fintech</option>
              <option value="ISO/MSP">ISO/MSP</option>
              <option value="Merchant">Merchant</option>
              <option value="MSB">MSB</option>
              <option value="Payfac">Payfac</option>
              <option value="Processor">Processor</option>
              <option value="PSP/IPSP">PSP/IPSP</option>
              <option value="TPPP">TPPP</option>
              <option value="VAR">VAR</option>
              <option value="Other">Other</option>
            </select>
          </div>
          <div class="pf-field">
            <label>Company Name</label>
            <input type="text" v-model="newItem.orgName" required :disabled="editStatus"/>
            <div class="error" v-if="!orgNameValid">This field is invalid.</div>
          </div>
        </div>
        <div class="pf-row split">
          <div class="pf-field">
            <label>Main Contact</label>
            <input type="text" v-model="newItem.mainContact" required :disabled="editStatus"/>
          </div>
          <div class="pf-field">
            <label>Contact Email</label>
            <input type="email" v-model="newItem.contactEmail" required :disabled="editStatus"/>
          </div>
        </div>
        <div class="pf-row split">
          <div class="pf-field">
            <label>Contact Phone</label>
            <input type="text" v-model="newItem.contactPhone" required :disabled="editStatus"/>
          </div>
        </div>
        <h3>ORGANIZATION SYSTEMS</h3>
        <div class="pf-row">
          <em class="pop-show add-emp" @click="addNewSystem = true">+ New System</em>
        </div>
        <template v-if="!editStatus">
          <div class="pf-row split">
            <div class="pf-field">
              <label>Category</label>
              <select v-model="newItem.category" required :disabled="editStatus">
                <option>Payments</option>
                <option>Lending</option>
                <option>Risk & Compliance</option>
                <option>Portal</option>
                <option>Billing & Invoicing</option>
                <option>Back-Office</option>
                <option>Software</option>
              </select>
            </div>
            <div class="pf-field">
              <label>System Name</label>
              <input type="text" v-model="newItem.name" required :disabled="editStatus"/>
              <div class="error" v-if="!sysNameValid">This field is invalid.</div>
            </div>
          </div>
          <div class="pf-row split">
            <div class="pf-field">
              <label>Version</label>
              <input type="text" v-model="newItem.version" required :disabled="editStatus"/>
            </div>
            <div class="pf-field">
              <label>Certification Date</label>
              {{newItem.certDate | date('MMMM D YYYY')}}
            </div>
          </div>
          <div class="pf-row split">
            <div class="pf-field">
              <label>Username</label>
              <input type="text" v-model="newItem.username" required :disabled="editStatus"/>
              <div class="error" v-if="!sysUserNameValid">This field is invalid.</div>
            </div>
            <div class="pf-field">
              <label>Key <em class="pop-show generate-btn" @click="generateKey()">Generate</em></label>
              {{newItem.keey}}
              <div class="error" v-if="!keyValid">This field is invalid.</div>
            </div>
          </div>
          <div class="pf-row">
            <div class="pf-field">
              <label>CallBack URL</label>
              <input type="text" v-model="newItem.callbackUrl" required :disabled="editStatus"/>
            </div>
          </div>
          <div class="pf-row">
            <div class="pf-field">
              <label>Notes</label>
              <input type="text" v-model="newItem.notes" :disabled="editStatus"/>
            </div>
          </div>
        </template>
        <template v-else v-for="system in connectedSystems">
          <hr>
          <div class="pf-row split">
            <div class="pf-field">
              <label>Category</label>
              <select v-model="system.category" required disabled>
                <option>Payments</option>
                <option>Lending</option>
                <option>Risk & Compliance</option>
                <option>Portal</option>
                <option>Billing & Invoicing</option>
                <option>Back-Office</option>
                <option>Software</option>
              </select>
            </div>
            <div class="pf-field">
              <label>System Name</label>
              <input type="text" v-model="system.name" required disabled/>
            </div>
          </div>
          <div class="pf-row split">
            <div class="pf-field">
              <label>Version</label>
              <input type="text" v-model="system.version" required disabled/>
            </div>
            <div class="pf-field">
              <label>Certification Date</label>
              {{system.certDate | date('MMMM D YYYY')}}
            </div>
          </div>
          <div class="pf-row split">
            <div class="pf-field">
              <label>Username</label>
              <input type="text" v-model="system.username" required disabled/>
            </div>
            <div class="pf-field">
              <label>Key</label>
              <input type="text" v-model="system.keey" required disabled/>
            </div>
          </div>
          <div class="pf-row">
            <div class="pf-field">
              <label>CallBack URL</label>
              <input type="text" v-model="system.callbackUrl" required disabled/>
            </div>
          </div>
          <div class="pf-row">
            <div class="pf-field">
              <label>Notes</label>
              <input type="text" v-model="system.notes" disabled/>
            </div>
          </div>
        </template>
        <template v-if="editStatus && addNewSystem">
          <hr>
          <div class="pf-row split">
            <div class="pf-field">
              <label>Category</label>
              <select v-model="newSystemItem.category" required>
                <option>Payments</option>
                <option>Lending</option>
                <option>Risk & Compliance</option>
                <option>Portal</option>
                <option>Billing & Invoicing</option>
                <option>Back-Office</option>
                <option>Software</option>
              </select>
            </div>
            <div class="pf-field">
              <label>System Name</label>
              <input type="text" v-model="newSystemItem.name" required/>
              <div class="error" v-if="!sysNameValidNew">This field is invalid.</div>
            </div>
          </div>
          <div class="pf-row split">
            <div class="pf-field">
              <label>Version</label>
              <input type="text" v-model="newSystemItem.version" required/>
            </div>
            <div class="pf-field">
              <label>Certification Date</label>
            </div>
          </div>
          <div class="pf-row split">
            <div class="pf-field">
              <label>Username</label>
              <input type="text" v-model="newSystemItem.username" required />
              <div class="error" v-if="!sysUserNameValidNew">This field is invalid.</div>
            </div>
            <div class="pf-field">
              <label>Key <em class="pop-show generate-btn" @click="generateKey()">Generate</em></label>
              {{newSystemItem.keey}}
              <div class="error" v-if="!keyValidNew">This field is invalid.</div>
            </div>
          </div>
          <div class="pf-row">
            <div class="pf-field">
              <label>CallBack URL</label>
              <input type="text" v-model="newSystemItem.callbackUrl" required/>
            </div>
          </div>
          <div class="pf-row">
            <div class="pf-field">
              <label>Notes</label>
              <input type="text" v-model="newSystemItem.notes" />
            </div>
          </div>
        </template>
        <h3>ORGANIZATION SETTINGS</h3>
        <div class="pf-row buttons">
          <a class="pop-btn close-popup" @click="addSystem = false; addNewSystem = false">Cancel</a>
          <template v-if="!addNewSystem">
            <a v-if="editStatus" class="edit pop-btn" @click="editStatus = false">Edit</a>
            <button v-else class="pop-btn">Save</button>
          </template>
          <template v-else>
            <button class="pop-btn">Save</button>
          </template>
        </div>
      </form></popup>
    <popup :open.sync="openSystem">
      <h2>System</h2>
        <h3>ORGANIZATION INFORMATION</h3>
        <div class="pf-row split">
          <div class="pf-field">
            <label>Organization Type</label>
            <select v-model="systemItem.orgType" required disabled>
              <option value="Acquirer">Acquirer</option>
              <option value="Developer">Developer</option>
              <option value="Enterprise Merchant">Enterprise Merchant</option>
              <option value="Financial">Financial</option>
              <option value="Fintech">Fintech</option>
              <option value="ISO/MSP">ISO/MSP</option>
              <option value="Merchant">Merchant</option>
              <option value="MSB">MSB</option>
              <option value="Payfac">Payfac</option>
              <option value="Processor">Processor</option>
              <option value="PSP/IPSP">PSP/IPSP</option>
              <option value="TPPP">TPPP</option>
              <option value="VAR">VAR</option>
              <option value="Other">Other</option>
            </select>
          </div>
          <div class="pf-field">
            <label>Company Name</label>
            <input type="text" v-model="systemItem.orgName" required disabled/>
          </div>
        </div>
        <h3>SYSTEM</h3>
        <template>
          <div class="pf-row split">
            <div class="pf-field">
              <label>Category</label>
              <select v-model="systemItem.category" required disabled>
                <option>Payments</option>
                <option>Lending</option>
                <option>Risk & Compliance</option>
                <option>Portal</option>
                <option>Billing & Invoicing</option>
                <option>Back-Office</option>
                <option>Software</option>
              </select>
            </div>
            <div class="pf-field">
              <label>System Name</label>
              <input type="text" v-model="systemItem.name" required disabled/>
            </div>
          </div>
          <div class="pf-row split">
            <div class="pf-field">
              <label>Version</label>
              <input type="text" v-model="systemItem.version" required disabled/>
            </div>
            <div class="pf-field">
              <label>Certification Date</label>
              {{systemItem.certDate | date('MMMM D YYYY')}}
            </div>
          </div>
          <div class="pf-row split">
            <div class="pf-field">
              <label>Username</label>
              <input type="text" v-model="systemItem.username" required disabled/>
            </div>
            <div class="pf-field">
              <label>Key</label>
              <input type="text" v-model="systemItem.keey" required disabled/>
            </div>
          </div>
          <div class="pf-row">
            <div class="pf-field">
              <label>CallBack URL</label>
              <input type="text" v-model="systemItem.callbackUrl" required disabled/>
            </div>
          </div>
          <div class="pf-row">
            <div class="pf-field">
              <label>Notes</label>
              <input type="text" v-model="systemItem.notes" disabled/>
            </div>
          </div>
        </template>
        <h3>SYSTEM INTEGRATIONS</h3>
        <div class="pf-row" v-for="system in systems">
          <div class="pf-field" >
            <label>{{system.providerShortname}} Connections: {{system.connections}}</label>
          </div>
        </div>

        <div class="pf-row buttons">
          <a class="pop-btn close-popup" @click="openSystem = false">Close</a>
        </div>
    </popup>
  </div>
</template>

<script>
  import VueEnglishdatepicker from 'vue-englishdatepicker';

  export default {
    components: {
      VueEnglishdatepicker,
    },

    data: () => ({
      editing: [],
      status: '',
      statusId: '',
      items: [],
      newItem: {
        orgType: "",
        orgName: "",
        mainContact: "",
        contactEmail: "",
        contactPhone: "",
        name: "",
        username: "",
        keey: "",
        version: "",
        category: "",
        callbackUrl: ""
      },
      addSystem: false,
      editStatus: false,
      appliedFilters: null,

      form: {
        organization: null,
        name: null,
        username: null
      },

      organizations : null,
      systems: null,
      addNewSystem: false,

      newSystemItem: {
        orgType: "",
        orgName: "",
        mainContact: "",
        contactEmail: "",
        contactPhone: "",
        name: "",
        username: "",
        keey: "",
        version: "",
        category: "",
        callbackUrl: ""
      },

      systemItem: {
        id: '',
        orgType: '',
        orgName: '',
        mainContact: '',
        contactEmail: '',
        contactPhone: '',
        name: '',
        orgId: '',
        username: '',
        keey: '',
        version: '',
        category: '',
        callbackUrl: '',
        notes: '',
        certDate: '',
      },
      openSystem: false,
      orgNameValid: true,
      sysNameValid: true,
      sysUserNameValid: true,
      keyValid: true,
      sysNameValidNew: true,
      sysUserNameValidNew: true,
      keyValidNew: true,
    }),

    computed: {
      data () {
        let data = this.items || []

        if (!this.appliedFilters) return data

        if (this.appliedFilters.organization) {
          data = data.filter(item => item.organization? item.organization.name == this.appliedFilters.organization : false)
        }

        if (this.appliedFilters.name) {
          data = data.filter(item => item.name == this.appliedFilters.name)
        }

        if (this.appliedFilters.username) {
          data = data.filter(item => item.username == this.appliedFilters.username)
        }

        return data
      },

      connectedSystems () {
        let data = this.items || []
        data = data.filter(item => item.orgId == this.newItem.orgId)
        return data
      },
    },

    methods: {
      newSystem() {
        this.addSystem = true
        this.editStatus = false
        this.newItem = {
          orgType: "",
          orgName: "",
          mainContact: "",
          contactEmail: "",
          contactPhone: "",
          name: "",
          username: "",
          keey: "",
          version: "",
          category: "",
          callbackUrl: ""
        }
      },

      search () {
        this.appliedFilters = { ...this.form }
      },

      resetItem() {
        this.appliedFilters = null
        Object.keys(this.form).forEach(key => this.form[key] = null)
      },

      async reload() {
        this.items = (await api.list("SystemSettings", {}, ["name:ASC"])).items;
        this.organizations = (await api.list("Organizations", {}, ["name:ASC"])).items;
      },
      async createItem() {
        await api.create("SystemSettings", this.newItem, true);
        this.reload();
        this.newItem = {
          name: "",
          username: "",
          keey: "",
          version: "",
          category: ""
        }
      },

      async createSystem() {
        if(this.newItem.id){
          if(this.addNewSystem){
            this.newSystemItem.orgId = this.newItem.orgId
            let valid = true
            let systems = this.items.filter(data => data.name == this.newSystemItem.name)
            if(systems.length > 0) {
              this.sysNameValidNew = false
              valid = false
            }else{
              this.sysNameValidNew = true
            }
            let systemUsernames = this.items.filter(data => data.username == this.newSystemItem.username)
            if(systemUsernames.length > 0) {
              this.sysUserNameValidNew = false
              valid = false
            }else{
              this.sysUserNameValidNew = true
            }
            if(this.newSystemItem.keey == '') {
              this.keyValidNew = false
              valid = false
            }else{
              this.keyValidNew = true
            }
            if(valid) {
              await api.action("Organizations", "new", "AddSystem", this.newSystemItem)
            }else{
              return
            }
          }else{
            await api.action("Organizations", "new", "UpdateOrganization", this.newItem)
          }
        }else{
          let valid = true
          let organizations = this.organizations.filter(data => data.name == this.newItem.orgName)
          if(organizations.length > 0) {
            this.orgNameValid = false
            valid = false
          }else{
            this.orgNameValid = true
          }
          let systems = this.items.filter(data => data.name == this.newItem.name)
          if(systems.length > 0) {
            this.sysNameValid = false
            valid = false
          }else{
            this.sysNameValid = true
          }
          let systemUsernames = this.items.filter(data => data.username == this.newItem.username)
          if(systemUsernames.length > 0) {
            this.sysUserNameValid = false
            valid = false
          }else{
            this.sysUserNameValid = true
          }
          if(this.newItem.keey == '') {
            this.keyValid = false
            valid = false
          }else{
            this.keyValid = true
          }
          if(valid){
            await api.action("Organizations", "new", "AddOrganization", this.newItem)
          }else{
            return
          }
        }
        this.reload()
        this.addSystem = false
        this.addNewSystem = false
        this.newItem = {
          orgType: "",
          orgName: "",
          mainContact: "",
          contactEmail: "",
          contactPhone: "",
          name: "",
          username: "",
          keey: "",
          version: "",
          category: "",
          callbackUrl: "",
          notes: ""
        }
      },

      async deleteItem(item) {
        let datas = this.items || []
        let connectedSystems = datas.filter(data => data.orgId == item.orgId)

        if(connectedSystems.length > 1 ){
          var r = confirm("Remove System Only");
          if (r == true) {
            await api.delete("SystemSettings", item.id);
            this.reload();
          } else {
            return;
          }
        }else{
          var r = confirm("This will also remove the Organization. Do you want to proceed?");
          if (r == true) {
            await api.delete("SystemSettings", item.id);
            await api.delete("Organizations", item.orgId);
            this.reload();
          } else {
            return;
          }
        }
      },

      async openUpdateStatus(id, status) {
        this.statusId = id;
        this.status = status;
        $("#statusPopup").toggleClass("open");
      },

      async updateStatus() {
        await api.update("SystemSettings", this.statusId, {"status":this.status}, true);
        $("#statusPopup").toggleClass("open");
        this.reload();
      },

      async saveUpdate (i) {
        try {
          const payload = {
            name: this.items[i].name,
            username: this.items[i].username,
            version: this.items[i].version,
            category: this.items[i].category,
            certDate: this.items[i].certDate,
            keey: this.items[i].keey,
            callbackUrl: this.items[i].callbackUrl
          }
          await api.update("SystemSettings", this.items[i].id, payload, true);
          this.cancelEdit(i)
        } catch (e) {
        }
      },

      cancelEdit (i) {
        const index = this.editing.indexOf(i)
        this.editing.splice(index, 1)
      },

      isEdit (index) {
        return this.editing.includes(index)
      },

      edit(index) {
        this.editing.push(index)
      },

      popupOrg(item) {
        this.newItem = {
          id: item.id,
          orgType: item.organization.type,
          orgName: item.organization.name,
          mainContact: item.organization.mainContact,
          contactEmail: item.organization.contactEmail,
          contactPhone: item.organization.contactPhone,
          name: item.name,
          orgId: item.orgId,
          username: item.username,
          keey: item.keey,
          version: item.version,
          category: item.category,
          callbackUrl: item.callbackUrl,
          notes: item.notes,
          certDate: item.certDate,
        }
        this.newSystemItem = {
          orgType: "",
            orgName: "",
            mainContact: "",
            contactEmail: "",
            contactPhone: "",
            name: "",
            username: "",
            keey: "",
            version: "",
            category: "",
            callbackUrl: ""
        }

        this.addSystem = true
        this.editStatus = true
      },

      async popupSystem(item) {
        this.systems = (await api.list("SystemIntegrations", {"system": item.name})).items;
        this.systemItem = {
          id: item.id,
          orgType: item.organization? item.organization.type : '',
          orgName: item.organization? item.organization.name : '',
          name: item.name,
          orgId: item.orgId,
          username: item.username,
          keey: item.keey,
          version: item.version,
          category: item.category,
          callbackUrl: item.callbackUrl,
          notes: item.notes,
          certDate: item.certDate,
        }
        this.openSystem = true
      },

      generateKey() {
        var result           = '';
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
        var charactersLength = characters.length;
        for ( var j = 0; j < 4; j++ ) {
          for ( var i = 0; i < 4; i++ ) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
          }
          result += "-"
        }

        for ( var i = 0; i < 4; i++ ) {
          result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }

        this.newItem.keey = result
        this.newSystemItem.keey = result
      }
    },
    mounted() {
      this.reload();
    },
  }
</script>
<style lang="scss" scoped>
  h3 {
    color: #0275be;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;
    border-bottom: 1px solid #99aabb;
  }

  .pop-form button.main {
    background: #0480c6;
    color: white;
    min-width: 120px;
    border: 0;
    box-sizing: border-box;
    height: 29px;
    border-radius: 15px;
    font-size: 12px;
    font-weight: bold;
  }
  .edit.pop-btn {
    margin-left: 16px;
    background: #0480c6 !important;
    border-color: transparent;
    color: white !important;
  }
  .generate-btn {
    display: inline-block;
    margin: 0 10px;
    padding: 0 10px;
    line-height: 26px;
    vertical-align: middle;
    background: #67c23a;
    border-radius: 4px;
    color: white;
  }
  .error {
    color: red;
  }
</style>
