var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("section", [
        _c(
          "em",
          {
            staticClass: "pop-show add-emp",
            on: {
              click: function($event) {
                return _vm.newSystem()
              }
            }
          },
          [_vm._v("+ New System")]
        )
      ]),
      _vm._v(" "),
      _c("section", { staticClass: "content-filters" }, [
        _c("div", { staticClass: "cof-box" }, [
          _c("a", { staticClass: "tsm-handle", attrs: { href: "" } }, [
            _vm._v("Filters")
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "cof-form" }, [
            _c("a", { staticClass: "cof-close" }),
            _vm._v(" "),
            _c("div", { staticClass: "cof-row" }, [
              _c("div", { staticClass: "cof-field" }, [
                _c("label", [_vm._v("Organization")]),
                _vm._v(" "),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.organization,
                        expression: "form.organization"
                      }
                    ],
                    on: {
                      change: function($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function(o) {
                            return o.selected
                          })
                          .map(function(o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.form,
                          "organization",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      }
                    }
                  },
                  [
                    _c("option", { attrs: { value: "" } }, [_vm._v("-")]),
                    _vm._v(" "),
                    _vm._l(_vm.organizations, function(organization, index) {
                      return organization.name
                        ? _c(
                            "option",
                            {
                              attrs: { "data-index": index },
                              domProps: { value: organization.name }
                            },
                            [
                              _vm._v(
                                "\n                  " +
                                  _vm._s(organization.name) +
                                  "\n                "
                              )
                            ]
                          )
                        : _vm._e()
                    })
                  ],
                  2
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "cof-field" }, [
                _c("label", [_vm._v("System Name")]),
                _vm._v(" "),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.name,
                        expression: "form.name"
                      }
                    ],
                    on: {
                      change: function($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function(o) {
                            return o.selected
                          })
                          .map(function(o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.form,
                          "name",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      }
                    }
                  },
                  [
                    _c("option", { attrs: { value: "" } }, [_vm._v("-")]),
                    _vm._v(" "),
                    _vm._l(_vm.items, function(item, index) {
                      return _c(
                        "option",
                        {
                          attrs: { "data-index": index },
                          domProps: { value: item.name }
                        },
                        [
                          _vm._v(
                            "\n                  " +
                              _vm._s(item.name) +
                              "\n                "
                          )
                        ]
                      )
                    })
                  ],
                  2
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "cof-field" }, [
                _c("label", [_vm._v("Username")]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.form.username,
                      expression: "form.username"
                    }
                  ],
                  attrs: { type: "text" },
                  domProps: { value: _vm.form.username },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.form, "username", $event.target.value)
                    }
                  }
                })
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "cof-field mobinl" }, [
                _c("label", [_vm._v(" ")]),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "main",
                    on: {
                      click: function($event) {
                        return _vm.search()
                      }
                    }
                  },
                  [_vm._v("Filter")]
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "cof-field mobinl" }, [
                _c("label", [_vm._v(" ")]),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "second",
                    on: {
                      click: function($event) {
                        return _vm.resetItem()
                      }
                    }
                  },
                  [_vm._v("Reset Fields")]
                )
              ])
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c("section", { staticClass: "content-tlist" }, [
        _c(
          "table",
          { staticClass: "content-table" },
          [
            _vm._m(0),
            _vm._v(" "),
            _vm._l(_vm.data, function(item, index) {
              return _c("tr", [
                _c("td", [
                  _c("span", [_vm._v("Organization")]),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      on: {
                        click: function($event) {
                          return _vm.popupOrg(item)
                        }
                      }
                    },
                    [
                      _vm._v(
                        _vm._s(item.organization ? item.organization.name : "")
                      )
                    ]
                  )
                ]),
                _vm._v(" "),
                _c(
                  "td",
                  [
                    _c("span", [_vm._v("System Name")]),
                    _vm._v(" "),
                    !_vm.isEdit(index)
                      ? [
                          _c(
                            "a",
                            {
                              on: {
                                click: function($event) {
                                  return _vm.popupSystem(item)
                                }
                              }
                            },
                            [_vm._v(_vm._s(item.name))]
                          )
                        ]
                      : _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: item.name,
                              expression: "item.name"
                            }
                          ],
                          attrs: { type: "text" },
                          domProps: { value: item.name },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(item, "name", $event.target.value)
                            }
                          }
                        })
                  ],
                  2
                ),
                _vm._v(" "),
                _c(
                  "td",
                  [
                    _c("span", [_vm._v("Version")]),
                    _vm._v(" "),
                    !_vm.isEdit(index)
                      ? [_vm._v(_vm._s(item.version))]
                      : _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: item.version,
                              expression: "item.version"
                            }
                          ],
                          attrs: { type: "text" },
                          domProps: { value: item.version },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(item, "version", $event.target.value)
                            }
                          }
                        })
                  ],
                  2
                ),
                _vm._v(" "),
                _c(
                  "td",
                  [
                    _c("span", [_vm._v("Category")]),
                    _vm._v(" "),
                    !_vm.isEdit(index)
                      ? [_vm._v(_vm._s(item.category))]
                      : _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: item.category,
                                expression: "item.category"
                              }
                            ],
                            on: {
                              change: function($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function(o) {
                                    return o.selected
                                  })
                                  .map(function(o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  item,
                                  "category",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              }
                            }
                          },
                          [
                            _c("option", { attrs: { value: "Payments" } }, [
                              _vm._v("Payments")
                            ]),
                            _vm._v(" "),
                            _c("option", { attrs: { value: "Lending" } }, [
                              _vm._v("Lending")
                            ]),
                            _vm._v(" "),
                            _c(
                              "option",
                              { attrs: { value: "Risk & Compliance" } },
                              [_vm._v("Risk & Compliance")]
                            ),
                            _vm._v(" "),
                            _c("option", { attrs: { value: "Portal" } }, [
                              _vm._v("Portal")
                            ]),
                            _vm._v(" "),
                            _c(
                              "option",
                              { attrs: { value: "Billing & Invoicing" } },
                              [_vm._v("Billing & Invoicing")]
                            ),
                            _vm._v(" "),
                            _c("option", { attrs: { value: "Back-Office" } }, [
                              _vm._v("Back-Office")
                            ]),
                            _vm._v(" "),
                            _c("option", { attrs: { value: "Software" } }, [
                              _vm._v("Software")
                            ])
                          ]
                        )
                  ],
                  2
                ),
                _vm._v(" "),
                _c(
                  "td",
                  {
                    on: {
                      click: function($event) {
                        return _vm.openUpdateStatus(item.id, item.status)
                      }
                    }
                  },
                  [
                    _c("span", [_vm._v("Status")]),
                    _vm._v(" "),
                    item.status === "Active"
                      ? _c("em", [_vm._v("Active")])
                      : _vm._e(),
                    item.status !== "Active"
                      ? _c("em", { staticClass: "c2" }, [
                          _vm._v(_vm._s(item.status))
                        ])
                      : _vm._e()
                  ]
                ),
                _vm._v(" "),
                _c(
                  "td",
                  [
                    _c("span", [_vm._v("Certified Date")]),
                    _vm._v(" "),
                    !_vm.isEdit(index)
                      ? [_vm._v(_vm._s(item.certDate))]
                      : _c("vue-englishdatepicker", {
                          model: {
                            value: item.certDate,
                            callback: function($$v) {
                              _vm.$set(item, "certDate", $$v)
                            },
                            expression: "item.certDate"
                          }
                        })
                  ],
                  2
                ),
                _vm._v(" "),
                _c(
                  "td",
                  [
                    _c("span", [_vm._v("Username")]),
                    _vm._v(" "),
                    !_vm.isEdit(index)
                      ? [_vm._v(_vm._s(item.username))]
                      : _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: item.username,
                              expression: "item.username"
                            }
                          ],
                          attrs: { type: "text" },
                          domProps: { value: item.username },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(item, "username", $event.target.value)
                            }
                          }
                        })
                  ],
                  2
                ),
                _vm._v(" "),
                _c(
                  "td",
                  [
                    _c("span", [_vm._v("Key")]),
                    _vm._v(" "),
                    !_vm.isEdit(index)
                      ? [_vm._v(_vm._s(item.keey))]
                      : _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: item.keey,
                              expression: "item.keey"
                            }
                          ],
                          attrs: { type: "text" },
                          domProps: { value: item.keey },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(item, "keey", $event.target.value)
                            }
                          }
                        })
                  ],
                  2
                ),
                _vm._v(" "),
                _c(
                  "td",
                  [
                    _c("span", [_vm._v("Callback URL")]),
                    _vm._v(" "),
                    !_vm.isEdit(index)
                      ? [_vm._v(_vm._s(item.callbackUrl))]
                      : _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: item.callbackUrl,
                              expression: "item.callbackUrl"
                            }
                          ],
                          attrs: { type: "text" },
                          domProps: { value: item.callbackUrl },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(item, "callbackUrl", $event.target.value)
                            }
                          }
                        })
                  ],
                  2
                ),
                _vm._v(" "),
                _c(
                  "td",
                  { staticStyle: { "text-align": "right" } },
                  [
                    _c("span", [_vm._v("Options")]),
                    _vm._v(" "),
                    _vm.isEdit(index)
                      ? [
                          _c(
                            "button",
                            {
                              staticClass: "button-handle save",
                              on: {
                                click: function($event) {
                                  return _vm.saveUpdate(index)
                                }
                              }
                            },
                            [_vm._v("Save")]
                          ),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass: "button-handle cancel",
                              on: {
                                click: function($event) {
                                  return _vm.cancelEdit(index)
                                }
                              }
                            },
                            [_vm._v("Cancel")]
                          )
                        ]
                      : [
                          item.status == "Disabled"
                            ? _c(
                                "button",
                                {
                                  staticClass: "second",
                                  on: {
                                    click: function($event) {
                                      return _vm.deleteItem(item)
                                    }
                                  }
                                },
                                [_vm._v("Remove")]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass: "button-handle update",
                              on: {
                                click: function($event) {
                                  return _vm.edit(index)
                                }
                              }
                            },
                            [_vm._v("Update")]
                          )
                        ]
                  ],
                  2
                )
              ])
            })
          ],
          2
        )
      ]),
      _vm._v(" "),
      _c("section", { staticClass: "popup", attrs: { id: "statusPopup" } }, [
        _c("div", { staticClass: "pop-inner" }, [
          _c("div", { staticClass: "pop-box" }, [
            _c("a", { staticClass: "close" }),
            _vm._v(" "),
            _c("h2", [_vm._v("Update Status")]),
            _vm._v(" "),
            _c("div", { staticClass: "pf-row split" }, [
              _c("div", { staticClass: "pf-field" }, [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.status,
                        expression: "status"
                      }
                    ],
                    on: {
                      change: function($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function(o) {
                            return o.selected
                          })
                          .map(function(o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.status = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      }
                    }
                  },
                  [
                    _c("option", { attrs: { value: "Review" } }, [
                      _vm._v("Review")
                    ]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "Testing" } }, [
                      _vm._v("Testing")
                    ]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "Active" } }, [
                      _vm._v("Active")
                    ]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "Disabled" } }, [
                      _vm._v("Disabled")
                    ])
                  ]
                )
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "pf-row buttons" }, [
              _c(
                "span",
                {
                  staticClass: "pop-btn",
                  on: {
                    click: function($event) {
                      return _vm.updateStatus()
                    }
                  }
                },
                [_vm._v("Confirm")]
              ),
              _vm._v(" "),
              _c(
                "a",
                { staticClass: "pop-btn close-popup", attrs: { href: "" } },
                [_vm._v("Cancel")]
              )
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c(
        "popup",
        {
          attrs: { open: _vm.addSystem },
          on: {
            "update:open": function($event) {
              _vm.addSystem = $event
            }
          }
        },
        [
          _c("h2", [_vm._v("Add New System")]),
          _vm._v(" "),
          _c(
            "form",
            {
              staticClass: "pop-form",
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.createSystem()
                }
              }
            },
            [
              _c("h3", [_vm._v("ORGANIZATION INFORMATION")]),
              _vm._v(" "),
              _c("div", { staticClass: "pf-row split" }, [
                _c("div", { staticClass: "pf-field" }, [
                  _c("label", [_vm._v("Organization Type")]),
                  _vm._v(" "),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.newItem.orgType,
                          expression: "newItem.orgType"
                        }
                      ],
                      attrs: { required: "", disabled: _vm.editStatus },
                      on: {
                        change: function($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function(o) {
                              return o.selected
                            })
                            .map(function(o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.newItem,
                            "orgType",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        }
                      }
                    },
                    [
                      _c("option", { attrs: { value: "Acquirer" } }, [
                        _vm._v("Acquirer")
                      ]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "Developer" } }, [
                        _vm._v("Developer")
                      ]),
                      _vm._v(" "),
                      _c(
                        "option",
                        { attrs: { value: "Enterprise Merchant" } },
                        [_vm._v("Enterprise Merchant")]
                      ),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "Financial" } }, [
                        _vm._v("Financial")
                      ]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "Fintech" } }, [
                        _vm._v("Fintech")
                      ]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "ISO/MSP" } }, [
                        _vm._v("ISO/MSP")
                      ]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "Merchant" } }, [
                        _vm._v("Merchant")
                      ]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "MSB" } }, [
                        _vm._v("MSB")
                      ]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "Payfac" } }, [
                        _vm._v("Payfac")
                      ]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "Processor" } }, [
                        _vm._v("Processor")
                      ]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "PSP/IPSP" } }, [
                        _vm._v("PSP/IPSP")
                      ]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "TPPP" } }, [
                        _vm._v("TPPP")
                      ]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "VAR" } }, [
                        _vm._v("VAR")
                      ]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "Other" } }, [
                        _vm._v("Other")
                      ])
                    ]
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "pf-field" }, [
                  _c("label", [_vm._v("Company Name")]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.newItem.orgName,
                        expression: "newItem.orgName"
                      }
                    ],
                    attrs: {
                      type: "text",
                      required: "",
                      disabled: _vm.editStatus
                    },
                    domProps: { value: _vm.newItem.orgName },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.newItem, "orgName", $event.target.value)
                      }
                    }
                  }),
                  _vm._v(" "),
                  !_vm.orgNameValid
                    ? _c("div", { staticClass: "error" }, [
                        _vm._v("This field is invalid.")
                      ])
                    : _vm._e()
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "pf-row split" }, [
                _c("div", { staticClass: "pf-field" }, [
                  _c("label", [_vm._v("Main Contact")]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.newItem.mainContact,
                        expression: "newItem.mainContact"
                      }
                    ],
                    attrs: {
                      type: "text",
                      required: "",
                      disabled: _vm.editStatus
                    },
                    domProps: { value: _vm.newItem.mainContact },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.newItem,
                          "mainContact",
                          $event.target.value
                        )
                      }
                    }
                  })
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "pf-field" }, [
                  _c("label", [_vm._v("Contact Email")]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.newItem.contactEmail,
                        expression: "newItem.contactEmail"
                      }
                    ],
                    attrs: {
                      type: "email",
                      required: "",
                      disabled: _vm.editStatus
                    },
                    domProps: { value: _vm.newItem.contactEmail },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.newItem,
                          "contactEmail",
                          $event.target.value
                        )
                      }
                    }
                  })
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "pf-row split" }, [
                _c("div", { staticClass: "pf-field" }, [
                  _c("label", [_vm._v("Contact Phone")]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.newItem.contactPhone,
                        expression: "newItem.contactPhone"
                      }
                    ],
                    attrs: {
                      type: "text",
                      required: "",
                      disabled: _vm.editStatus
                    },
                    domProps: { value: _vm.newItem.contactPhone },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.newItem,
                          "contactPhone",
                          $event.target.value
                        )
                      }
                    }
                  })
                ])
              ]),
              _vm._v(" "),
              _c("h3", [_vm._v("ORGANIZATION SYSTEMS")]),
              _vm._v(" "),
              _c("div", { staticClass: "pf-row" }, [
                _c(
                  "em",
                  {
                    staticClass: "pop-show add-emp",
                    on: {
                      click: function($event) {
                        _vm.addNewSystem = true
                      }
                    }
                  },
                  [_vm._v("+ New System")]
                )
              ]),
              _vm._v(" "),
              !_vm.editStatus
                ? [
                    _c("div", { staticClass: "pf-row split" }, [
                      _c("div", { staticClass: "pf-field" }, [
                        _c("label", [_vm._v("Category")]),
                        _vm._v(" "),
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.newItem.category,
                                expression: "newItem.category"
                              }
                            ],
                            attrs: { required: "", disabled: _vm.editStatus },
                            on: {
                              change: function($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function(o) {
                                    return o.selected
                                  })
                                  .map(function(o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  _vm.newItem,
                                  "category",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              }
                            }
                          },
                          [
                            _c("option", [_vm._v("Payments")]),
                            _vm._v(" "),
                            _c("option", [_vm._v("Lending")]),
                            _vm._v(" "),
                            _c("option", [_vm._v("Risk & Compliance")]),
                            _vm._v(" "),
                            _c("option", [_vm._v("Portal")]),
                            _vm._v(" "),
                            _c("option", [_vm._v("Billing & Invoicing")]),
                            _vm._v(" "),
                            _c("option", [_vm._v("Back-Office")]),
                            _vm._v(" "),
                            _c("option", [_vm._v("Software")])
                          ]
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "pf-field" }, [
                        _c("label", [_vm._v("System Name")]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.newItem.name,
                              expression: "newItem.name"
                            }
                          ],
                          attrs: {
                            type: "text",
                            required: "",
                            disabled: _vm.editStatus
                          },
                          domProps: { value: _vm.newItem.name },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(_vm.newItem, "name", $event.target.value)
                            }
                          }
                        }),
                        _vm._v(" "),
                        !_vm.sysNameValid
                          ? _c("div", { staticClass: "error" }, [
                              _vm._v("This field is invalid.")
                            ])
                          : _vm._e()
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "pf-row split" }, [
                      _c("div", { staticClass: "pf-field" }, [
                        _c("label", [_vm._v("Version")]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.newItem.version,
                              expression: "newItem.version"
                            }
                          ],
                          attrs: {
                            type: "text",
                            required: "",
                            disabled: _vm.editStatus
                          },
                          domProps: { value: _vm.newItem.version },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.newItem,
                                "version",
                                $event.target.value
                              )
                            }
                          }
                        })
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "pf-field" }, [
                        _c("label", [_vm._v("Certification Date")]),
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm._f("date")(
                                _vm.newItem.certDate,
                                "MMMM D YYYY"
                              )
                            ) +
                            "\n          "
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "pf-row split" }, [
                      _c("div", { staticClass: "pf-field" }, [
                        _c("label", [_vm._v("Username")]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.newItem.username,
                              expression: "newItem.username"
                            }
                          ],
                          attrs: {
                            type: "text",
                            required: "",
                            disabled: _vm.editStatus
                          },
                          domProps: { value: _vm.newItem.username },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.newItem,
                                "username",
                                $event.target.value
                              )
                            }
                          }
                        }),
                        _vm._v(" "),
                        !_vm.sysUserNameValid
                          ? _c("div", { staticClass: "error" }, [
                              _vm._v("This field is invalid.")
                            ])
                          : _vm._e()
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "pf-field" }, [
                        _c("label", [
                          _vm._v("Key "),
                          _c(
                            "em",
                            {
                              staticClass: "pop-show generate-btn",
                              on: {
                                click: function($event) {
                                  return _vm.generateKey()
                                }
                              }
                            },
                            [_vm._v("Generate")]
                          )
                        ]),
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.newItem.keey) +
                            "\n            "
                        ),
                        !_vm.keyValid
                          ? _c("div", { staticClass: "error" }, [
                              _vm._v("This field is invalid.")
                            ])
                          : _vm._e()
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "pf-row" }, [
                      _c("div", { staticClass: "pf-field" }, [
                        _c("label", [_vm._v("CallBack URL")]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.newItem.callbackUrl,
                              expression: "newItem.callbackUrl"
                            }
                          ],
                          attrs: {
                            type: "text",
                            required: "",
                            disabled: _vm.editStatus
                          },
                          domProps: { value: _vm.newItem.callbackUrl },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.newItem,
                                "callbackUrl",
                                $event.target.value
                              )
                            }
                          }
                        })
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "pf-row" }, [
                      _c("div", { staticClass: "pf-field" }, [
                        _c("label", [_vm._v("Notes")]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.newItem.notes,
                              expression: "newItem.notes"
                            }
                          ],
                          attrs: { type: "text", disabled: _vm.editStatus },
                          domProps: { value: _vm.newItem.notes },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.newItem,
                                "notes",
                                $event.target.value
                              )
                            }
                          }
                        })
                      ])
                    ])
                  ]
                : _vm._l(_vm.connectedSystems, function(system) {
                    return [
                      _c("hr"),
                      _vm._v(" "),
                      _c("div", { staticClass: "pf-row split" }, [
                        _c("div", { staticClass: "pf-field" }, [
                          _c("label", [_vm._v("Category")]),
                          _vm._v(" "),
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: system.category,
                                  expression: "system.category"
                                }
                              ],
                              attrs: { required: "", disabled: "" },
                              on: {
                                change: function($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function(o) {
                                      return o.selected
                                    })
                                    .map(function(o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.$set(
                                    system,
                                    "category",
                                    $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  )
                                }
                              }
                            },
                            [
                              _c("option", [_vm._v("Payments")]),
                              _vm._v(" "),
                              _c("option", [_vm._v("Lending")]),
                              _vm._v(" "),
                              _c("option", [_vm._v("Risk & Compliance")]),
                              _vm._v(" "),
                              _c("option", [_vm._v("Portal")]),
                              _vm._v(" "),
                              _c("option", [_vm._v("Billing & Invoicing")]),
                              _vm._v(" "),
                              _c("option", [_vm._v("Back-Office")]),
                              _vm._v(" "),
                              _c("option", [_vm._v("Software")])
                            ]
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "pf-field" }, [
                          _c("label", [_vm._v("System Name")]),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: system.name,
                                expression: "system.name"
                              }
                            ],
                            attrs: { type: "text", required: "", disabled: "" },
                            domProps: { value: system.name },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(system, "name", $event.target.value)
                              }
                            }
                          })
                        ])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "pf-row split" }, [
                        _c("div", { staticClass: "pf-field" }, [
                          _c("label", [_vm._v("Version")]),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: system.version,
                                expression: "system.version"
                              }
                            ],
                            attrs: { type: "text", required: "", disabled: "" },
                            domProps: { value: system.version },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(system, "version", $event.target.value)
                              }
                            }
                          })
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "pf-field" }, [
                          _c("label", [_vm._v("Certification Date")]),
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm._f("date")(system.certDate, "MMMM D YYYY")
                              ) +
                              "\n          "
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "pf-row split" }, [
                        _c("div", { staticClass: "pf-field" }, [
                          _c("label", [_vm._v("Username")]),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: system.username,
                                expression: "system.username"
                              }
                            ],
                            attrs: { type: "text", required: "", disabled: "" },
                            domProps: { value: system.username },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  system,
                                  "username",
                                  $event.target.value
                                )
                              }
                            }
                          })
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "pf-field" }, [
                          _c("label", [_vm._v("Key")]),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: system.keey,
                                expression: "system.keey"
                              }
                            ],
                            attrs: { type: "text", required: "", disabled: "" },
                            domProps: { value: system.keey },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(system, "keey", $event.target.value)
                              }
                            }
                          })
                        ])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "pf-row" }, [
                        _c("div", { staticClass: "pf-field" }, [
                          _c("label", [_vm._v("CallBack URL")]),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: system.callbackUrl,
                                expression: "system.callbackUrl"
                              }
                            ],
                            attrs: { type: "text", required: "", disabled: "" },
                            domProps: { value: system.callbackUrl },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  system,
                                  "callbackUrl",
                                  $event.target.value
                                )
                              }
                            }
                          })
                        ])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "pf-row" }, [
                        _c("div", { staticClass: "pf-field" }, [
                          _c("label", [_vm._v("Notes")]),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: system.notes,
                                expression: "system.notes"
                              }
                            ],
                            attrs: { type: "text", disabled: "" },
                            domProps: { value: system.notes },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(system, "notes", $event.target.value)
                              }
                            }
                          })
                        ])
                      ])
                    ]
                  }),
              _vm._v(" "),
              _vm.editStatus && _vm.addNewSystem
                ? [
                    _c("hr"),
                    _vm._v(" "),
                    _c("div", { staticClass: "pf-row split" }, [
                      _c("div", { staticClass: "pf-field" }, [
                        _c("label", [_vm._v("Category")]),
                        _vm._v(" "),
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.newSystemItem.category,
                                expression: "newSystemItem.category"
                              }
                            ],
                            attrs: { required: "" },
                            on: {
                              change: function($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function(o) {
                                    return o.selected
                                  })
                                  .map(function(o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  _vm.newSystemItem,
                                  "category",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              }
                            }
                          },
                          [
                            _c("option", [_vm._v("Payments")]),
                            _vm._v(" "),
                            _c("option", [_vm._v("Lending")]),
                            _vm._v(" "),
                            _c("option", [_vm._v("Risk & Compliance")]),
                            _vm._v(" "),
                            _c("option", [_vm._v("Portal")]),
                            _vm._v(" "),
                            _c("option", [_vm._v("Billing & Invoicing")]),
                            _vm._v(" "),
                            _c("option", [_vm._v("Back-Office")]),
                            _vm._v(" "),
                            _c("option", [_vm._v("Software")])
                          ]
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "pf-field" }, [
                        _c("label", [_vm._v("System Name")]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.newSystemItem.name,
                              expression: "newSystemItem.name"
                            }
                          ],
                          attrs: { type: "text", required: "" },
                          domProps: { value: _vm.newSystemItem.name },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.newSystemItem,
                                "name",
                                $event.target.value
                              )
                            }
                          }
                        }),
                        _vm._v(" "),
                        !_vm.sysNameValidNew
                          ? _c("div", { staticClass: "error" }, [
                              _vm._v("This field is invalid.")
                            ])
                          : _vm._e()
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "pf-row split" }, [
                      _c("div", { staticClass: "pf-field" }, [
                        _c("label", [_vm._v("Version")]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.newSystemItem.version,
                              expression: "newSystemItem.version"
                            }
                          ],
                          attrs: { type: "text", required: "" },
                          domProps: { value: _vm.newSystemItem.version },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.newSystemItem,
                                "version",
                                $event.target.value
                              )
                            }
                          }
                        })
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "pf-field" }, [
                        _c("label", [_vm._v("Certification Date")])
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "pf-row split" }, [
                      _c("div", { staticClass: "pf-field" }, [
                        _c("label", [_vm._v("Username")]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.newSystemItem.username,
                              expression: "newSystemItem.username"
                            }
                          ],
                          attrs: { type: "text", required: "" },
                          domProps: { value: _vm.newSystemItem.username },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.newSystemItem,
                                "username",
                                $event.target.value
                              )
                            }
                          }
                        }),
                        _vm._v(" "),
                        !_vm.sysUserNameValidNew
                          ? _c("div", { staticClass: "error" }, [
                              _vm._v("This field is invalid.")
                            ])
                          : _vm._e()
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "pf-field" }, [
                        _c("label", [
                          _vm._v("Key "),
                          _c(
                            "em",
                            {
                              staticClass: "pop-show generate-btn",
                              on: {
                                click: function($event) {
                                  return _vm.generateKey()
                                }
                              }
                            },
                            [_vm._v("Generate")]
                          )
                        ]),
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.newSystemItem.keey) +
                            "\n            "
                        ),
                        !_vm.keyValidNew
                          ? _c("div", { staticClass: "error" }, [
                              _vm._v("This field is invalid.")
                            ])
                          : _vm._e()
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "pf-row" }, [
                      _c("div", { staticClass: "pf-field" }, [
                        _c("label", [_vm._v("CallBack URL")]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.newSystemItem.callbackUrl,
                              expression: "newSystemItem.callbackUrl"
                            }
                          ],
                          attrs: { type: "text", required: "" },
                          domProps: { value: _vm.newSystemItem.callbackUrl },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.newSystemItem,
                                "callbackUrl",
                                $event.target.value
                              )
                            }
                          }
                        })
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "pf-row" }, [
                      _c("div", { staticClass: "pf-field" }, [
                        _c("label", [_vm._v("Notes")]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.newSystemItem.notes,
                              expression: "newSystemItem.notes"
                            }
                          ],
                          attrs: { type: "text" },
                          domProps: { value: _vm.newSystemItem.notes },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.newSystemItem,
                                "notes",
                                $event.target.value
                              )
                            }
                          }
                        })
                      ])
                    ])
                  ]
                : _vm._e(),
              _vm._v(" "),
              _c("h3", [_vm._v("ORGANIZATION SETTINGS")]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "pf-row buttons" },
                [
                  _c(
                    "a",
                    {
                      staticClass: "pop-btn close-popup",
                      on: {
                        click: function($event) {
                          _vm.addSystem = false
                          _vm.addNewSystem = false
                        }
                      }
                    },
                    [_vm._v("Cancel")]
                  ),
                  _vm._v(" "),
                  !_vm.addNewSystem
                    ? [
                        _vm.editStatus
                          ? _c(
                              "a",
                              {
                                staticClass: "edit pop-btn",
                                on: {
                                  click: function($event) {
                                    _vm.editStatus = false
                                  }
                                }
                              },
                              [_vm._v("Edit")]
                            )
                          : _c("button", { staticClass: "pop-btn" }, [
                              _vm._v("Save")
                            ])
                      ]
                    : [
                        _c("button", { staticClass: "pop-btn" }, [
                          _vm._v("Save")
                        ])
                      ]
                ],
                2
              )
            ],
            2
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "popup",
        {
          attrs: { open: _vm.openSystem },
          on: {
            "update:open": function($event) {
              _vm.openSystem = $event
            }
          }
        },
        [
          _c("h2", [_vm._v("System")]),
          _vm._v(" "),
          _c("h3", [_vm._v("ORGANIZATION INFORMATION")]),
          _vm._v(" "),
          _c("div", { staticClass: "pf-row split" }, [
            _c("div", { staticClass: "pf-field" }, [
              _c("label", [_vm._v("Organization Type")]),
              _vm._v(" "),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.systemItem.orgType,
                      expression: "systemItem.orgType"
                    }
                  ],
                  attrs: { required: "", disabled: "" },
                  on: {
                    change: function($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function(o) {
                          return o.selected
                        })
                        .map(function(o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.systemItem,
                        "orgType",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    }
                  }
                },
                [
                  _c("option", { attrs: { value: "Acquirer" } }, [
                    _vm._v("Acquirer")
                  ]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "Developer" } }, [
                    _vm._v("Developer")
                  ]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "Enterprise Merchant" } }, [
                    _vm._v("Enterprise Merchant")
                  ]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "Financial" } }, [
                    _vm._v("Financial")
                  ]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "Fintech" } }, [
                    _vm._v("Fintech")
                  ]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "ISO/MSP" } }, [
                    _vm._v("ISO/MSP")
                  ]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "Merchant" } }, [
                    _vm._v("Merchant")
                  ]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "MSB" } }, [_vm._v("MSB")]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "Payfac" } }, [
                    _vm._v("Payfac")
                  ]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "Processor" } }, [
                    _vm._v("Processor")
                  ]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "PSP/IPSP" } }, [
                    _vm._v("PSP/IPSP")
                  ]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "TPPP" } }, [_vm._v("TPPP")]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "VAR" } }, [_vm._v("VAR")]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "Other" } }, [_vm._v("Other")])
                ]
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "pf-field" }, [
              _c("label", [_vm._v("Company Name")]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.systemItem.orgName,
                    expression: "systemItem.orgName"
                  }
                ],
                attrs: { type: "text", required: "", disabled: "" },
                domProps: { value: _vm.systemItem.orgName },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.systemItem, "orgName", $event.target.value)
                  }
                }
              })
            ])
          ]),
          _vm._v(" "),
          _c("h3", [_vm._v("SYSTEM")]),
          _vm._v(" "),
          [
            _c("div", { staticClass: "pf-row split" }, [
              _c("div", { staticClass: "pf-field" }, [
                _c("label", [_vm._v("Category")]),
                _vm._v(" "),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.systemItem.category,
                        expression: "systemItem.category"
                      }
                    ],
                    attrs: { required: "", disabled: "" },
                    on: {
                      change: function($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function(o) {
                            return o.selected
                          })
                          .map(function(o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.systemItem,
                          "category",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      }
                    }
                  },
                  [
                    _c("option", [_vm._v("Payments")]),
                    _vm._v(" "),
                    _c("option", [_vm._v("Lending")]),
                    _vm._v(" "),
                    _c("option", [_vm._v("Risk & Compliance")]),
                    _vm._v(" "),
                    _c("option", [_vm._v("Portal")]),
                    _vm._v(" "),
                    _c("option", [_vm._v("Billing & Invoicing")]),
                    _vm._v(" "),
                    _c("option", [_vm._v("Back-Office")]),
                    _vm._v(" "),
                    _c("option", [_vm._v("Software")])
                  ]
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "pf-field" }, [
                _c("label", [_vm._v("System Name")]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.systemItem.name,
                      expression: "systemItem.name"
                    }
                  ],
                  attrs: { type: "text", required: "", disabled: "" },
                  domProps: { value: _vm.systemItem.name },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.systemItem, "name", $event.target.value)
                    }
                  }
                })
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "pf-row split" }, [
              _c("div", { staticClass: "pf-field" }, [
                _c("label", [_vm._v("Version")]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.systemItem.version,
                      expression: "systemItem.version"
                    }
                  ],
                  attrs: { type: "text", required: "", disabled: "" },
                  domProps: { value: _vm.systemItem.version },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.systemItem, "version", $event.target.value)
                    }
                  }
                })
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "pf-field" }, [
                _c("label", [_vm._v("Certification Date")]),
                _vm._v(
                  "\n            " +
                    _vm._s(
                      _vm._f("date")(_vm.systemItem.certDate, "MMMM D YYYY")
                    ) +
                    "\n          "
                )
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "pf-row split" }, [
              _c("div", { staticClass: "pf-field" }, [
                _c("label", [_vm._v("Username")]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.systemItem.username,
                      expression: "systemItem.username"
                    }
                  ],
                  attrs: { type: "text", required: "", disabled: "" },
                  domProps: { value: _vm.systemItem.username },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.systemItem, "username", $event.target.value)
                    }
                  }
                })
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "pf-field" }, [
                _c("label", [_vm._v("Key")]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.systemItem.keey,
                      expression: "systemItem.keey"
                    }
                  ],
                  attrs: { type: "text", required: "", disabled: "" },
                  domProps: { value: _vm.systemItem.keey },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.systemItem, "keey", $event.target.value)
                    }
                  }
                })
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "pf-row" }, [
              _c("div", { staticClass: "pf-field" }, [
                _c("label", [_vm._v("CallBack URL")]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.systemItem.callbackUrl,
                      expression: "systemItem.callbackUrl"
                    }
                  ],
                  attrs: { type: "text", required: "", disabled: "" },
                  domProps: { value: _vm.systemItem.callbackUrl },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.systemItem,
                        "callbackUrl",
                        $event.target.value
                      )
                    }
                  }
                })
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "pf-row" }, [
              _c("div", { staticClass: "pf-field" }, [
                _c("label", [_vm._v("Notes")]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.systemItem.notes,
                      expression: "systemItem.notes"
                    }
                  ],
                  attrs: { type: "text", disabled: "" },
                  domProps: { value: _vm.systemItem.notes },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.systemItem, "notes", $event.target.value)
                    }
                  }
                })
              ])
            ])
          ],
          _vm._v(" "),
          _c("h3", [_vm._v("SYSTEM INTEGRATIONS")]),
          _vm._v(" "),
          _vm._l(_vm.systems, function(system) {
            return _c("div", { staticClass: "pf-row" }, [
              _c("div", { staticClass: "pf-field" }, [
                _c("label", [
                  _vm._v(
                    _vm._s(system.providerShortname) +
                      " Connections: " +
                      _vm._s(system.connections)
                  )
                ])
              ])
            ])
          }),
          _vm._v(" "),
          _c("div", { staticClass: "pf-row buttons" }, [
            _c(
              "a",
              {
                staticClass: "pop-btn close-popup",
                on: {
                  click: function($event) {
                    _vm.openSystem = false
                  }
                }
              },
              [_vm._v("Close")]
            )
          ])
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("th", [_vm._v("Organization")]),
      _vm._v(" "),
      _c("th", [_vm._v("System Name")]),
      _vm._v(" "),
      _c("th", [_vm._v("Version")]),
      _vm._v(" "),
      _c("th", [_vm._v("Category")]),
      _vm._v(" "),
      _c("th", [_vm._v("Status")]),
      _vm._v(" "),
      _c("th", [_vm._v("Certified Date")]),
      _vm._v(" "),
      _c("th", [_vm._v("Username")]),
      _vm._v(" "),
      _c("th", [_vm._v("Key")]),
      _vm._v(" "),
      _c("th", [_vm._v("Callback URL")]),
      _vm._v(" "),
      _c("th", { staticStyle: { "text-align": "right" } }, [_vm._v("Options")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }