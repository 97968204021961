var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form-item",
    {
      class: { full: _vm.full },
      attrs: {
        label: _vm.label,
        validator: _vm.validator,
        "no-style": _vm.noStyle,
        "no-margin": _vm.noMargin
      }
    },
    [
      _c("textarea", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.val,
            expression: "val"
          }
        ],
        class: _vm.classes,
        attrs: {
          placeholder: "Enter " + (_vm.label || "text"),
          disabled: _vm.disabled,
          rows: _vm.rows
        },
        domProps: { value: _vm.val },
        on: {
          blur: function($event) {
            return _vm.$emit("blur")
          },
          input: [
            function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.val = $event.target.value
            },
            _vm.mixin_autoResize_resize
          ]
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }