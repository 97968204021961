import { render, staticRenderFns } from "./FormItem.vue?vue&type=template&id=afc63efe&scoped=true&"
import script from "./FormItem.vue?vue&type=script&lang=js&"
export * from "./FormItem.vue?vue&type=script&lang=js&"
import style0 from "./FormItem.vue?vue&type=style&index=0&id=afc63efe&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "afc63efe",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/monbi/bbx/assets/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('afc63efe')) {
      api.createRecord('afc63efe', component.options)
    } else {
      api.reload('afc63efe', component.options)
    }
    module.hot.accept("./FormItem.vue?vue&type=template&id=afc63efe&scoped=true&", function () {
      api.rerender('afc63efe', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "js/components/ui/forms/FormItem.vue"
export default component.exports