import $ from 'jquery';

var api={
  request(method, url, data) {
    return new Promise((resolve, reject)=>{
      $.ajax({
        url: window.appData.apiRoot+url,
        data: method=="GET"?$.param(data):JSON.stringify(data),
        contentType: "application/json; charset=utf-8",
        dataType: "json",
        processData: false,
        error: (jqXHR, status, error)=>{
          reject(error);
        },
        type: method,
        success: (data)=>{
          resolve(data);
        },
      });
    });
  },
  async login(username, password) {
    return await api.request("POST", "/login", {'email':username, 'password':password});
  },
  async resetpw(username, password, newPassword) {
    return await api.request("POST", "/login/resetpw", {username, password, newPassword});
  },
  async logout() {
    return await api.request("POST", "/logout", {});
  },
  async root() {
    return await api.request("GET", "", {});
  },
  async list(entity, filter, order, limit, start) {
    var data={};
    if (filter) data.q=filter;
    if (order) data.by=order;
    if (limit) {
      data.limit=limit;
      data.start=start || 0;
    }
    return await api.request("GET", "/"+entity, data);
  },
  async get(entity, id) {
    return await api.request("GET", "/"+entity+"/"+id, {});
  },
  async getProviderItem(entity, id) {
    return await api.request("GET", "/getProviderItem/"+id, {});
  },
  async update(entity, id, data, returnObj) {
    var hasFile=null;
    var actualData=JSON.parse(JSON.stringify(data));
    for(var field in data) {
      if (data[field] instanceof File) {
        hasFile=data[field];
        delete actualData[field];
      }
    }
    var result=await api.request("PUT", "/"+entity+"/"+id, {obj: actualData, returnObj: !!returnObj});
    if (hasFile) {
      await api.upload(entity, id, hasFile);
    }
    return result;
  },
  async delete(entity, id) {
    return await api.request("DELETE", "/"+entity+"/"+id, {});
  },
  async create(entity, data, returnObj) {
    var hasFile=null;
    var actualData=JSON.parse(JSON.stringify(data));
    for(var field in data) {
      if (data[field] instanceof File) {
        hasFile=data[field];
        delete actualData[field];
      }
    }
    var result=await api.request("POST", "/"+entity, {obj: data, returnObj: !!returnObj});
    if (hasFile) {
      await api.upload(entity, result.id, hasFile);
    }
    return result;
  },
  upload(entity, id, file, extraFields) {
    extraFields=extraFields || {};
    if (window.appData.tempLink)
      extraFields.tempLink=window.appData.tempLink;
    console.log(extraFields);
    var url="/"+entity+"/"+id;
    return new Promise((resolve, reject)=>{
      var formData=new FormData();
      formData.append("file", file);
      if (extraFields)
        for(var field in extraFields)
          formData.append(field, extraFields[field]);
      $.ajax({
        url: window.appData.apiRoot+url,
        data: formData,
        contentType: false,
        dataType: "json",
        processData: false,
        error: (jqXHR, status, error)=>{
          reject(error);
        },
        type: "POST",
        success: (data)=>{
          resolve(data);
        },
      });
    });    
  },
  async action(entity, id, action, params) {
    return await api.request("POST", "/"+entity+"/"+id+"/"+action, params);
  },
};

window.api=api;
