<template>
  <div>
    <section class="content-filters">
      <basic-form>
        <div class="cof-row">
          <div class="full-w">
            <mnb-input
            v-model="form.message"
            label="Message" />
            <span v-if="$v.form.message.$dirty && $v.form.message.$invalid" class="error">This field is required !</span>
          </div>
        </div>
        <div class="cof-row">
          <div class="cof-field mobinl">
            <label>&nbsp;</label>
            <button class="main" @click="save()">Add Message</button>
          </div>
          <div class="cof-field mobinl">
            <label>&nbsp;</label>
            <button class="second" @click="resetField()">Reset Fields</button>
          </div>
        </div>
      </basic-form>
    </section>

    <section  class="content-tlist">
      <table class="content-table">
        <tr>
          <th>Date/Time</th>
          <th>By</th>
          <th>Messages</th>
          <th>Options</th>
        </tr>
        <tr v-for="(item, i) in messagesSorted" :key="i">
          <td><span>Date</span>{{ item.createdAt | date }}</td>
          <td><span>By</span>{{ item.createdBy.firstName }} {{ item.createdBy.lastName }}</td>
          <td style="width: 500px; padding-right: 10px;">
            <span>Messages</span>
            <template v-if="!isEdit(i)"> {{ item.message }}</template>
            <mnb-textarea
              v-else
              :ref="`input_${i}`"
              v-model="editingText[i]"
              :classes="{ 'noUpdate': isEdit(i), 'messageField': true }"
              @input="mixin_autoResize_resize"
              full/>
          </td>
          <td>
            <span>Options</span>
            <template v-if="isEdit(i)">
              <button @click="saveUpdate(i)" class="message-handle save">Save</button>
              <button @click="cancelEdit(i)" class="message-handle cancel">Cancel</button>
            </template>
            <template v-else>
              <button @click="editMessage(i)" class="message-handle update">Update</button>
              <button @click="deleteMessage(item.id)" class="message-handle remove">Remove</button>
            </template>
          </td>
        </tr>
      </table>
    </section>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import BasicForm from './components/ui/forms/Form'
import MnbInput from './components/ui/forms/Input'
import MnbTextarea from './components/ui/forms/Textarea'

import mixinAutoResize from "./components/ui/forms/autoresize.js"
import inputMixin from "./components/ui/forms/input-mixin.js"
import { EventBus } from './app';

export default {
  name: 'system-messages',

  mixins: [mixinAutoResize],

  components: {
    BasicForm,
    MnbInput,
    MnbTextarea
  },

  data() {
    return {
      form: {
        message: null
      },
      editing: [],
      editingText: {},
      messages: []
    }
  },

  computed: {
    messagesSorted () {
      return this.messages.sort((a, b) => {
        if (a.id > b.id) return -1
        if (a.id < b.id) return 1
        return 0
      })
    }
  },

  async created () {
    this.reload()
  },

  methods: {
    async reload() {
      this.messages = (await api.list("SystemMessage", {}, ["id:ASC"])).items;
    },

    isEdit (index) {
      return this.editing.includes(index)
    },

    async save () {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        const loader = this.$loading.show()
        try {
          const payload = {
            message: this.form.message,
            createdAt: 'CURRENT_TIMESTAMP',
            createdBy: window.appData.currentUser.id
          }

          await api.create("SystemMessage", payload, true)
          this.form.message = null
          this.reload()
          EventBus.$emit('message-updated');
          this.$v.$reset()
        } catch (e) {
        } finally {
          loader.hide()
        }
      }
    },

    resetField() {
      this.form.message = null
    },

    async deleteMessage(id) {
      if(confirm(`Are you sure to delete System Message?`)) {
        const loader = this.$loading.show()
        await api.delete("SystemMessage", id)
        this.reload()
        EventBus.$emit('message-updated');
        loader.hide()
      }
    },

    async saveUpdate (i) {
      if (this.editingText[i]) {
        const loader = this.$loading.show()
        try {
            const payload = {
              message: this.editingText[i]
            }

          await api.update("SystemMessage",this.messages[i].id, payload, true)
          this.cancelEdit(i)
          this.reload()
          EventBus.$emit('message-updated');
        } catch (e) {
        } finally {
          loader.hide()
        }
      }
    },

    cancelEdit (i) {
      const index = this.editing.indexOf(i)
      this.editing.splice(index, 1)
      delete this.editingText[index]
    },

    editMessage(index) {
      this.editing.push(index)
      this.editingText[index] = this.messages[index].message
      this.$nextTick(() => this.$refs[`input_${index}`][0].$el.getElementsByTagName('textarea')[0].focus())
    }
  },

  validations: {
    form: {
      message: { required }
    }
  }
}
</script>

<style lang="scss" scoped>
  .second {
    margin-left: 70px;
  }
  .error {
    display: block;
    margin-top: 5px;
    color: #d23131;
  }
  .message-handle {
    display: inline-block;
    padding: 0 10px;
    line-height: 26px;
    vertical-align: middle;
    border-radius: 4px;
    min-width: 70px;
    text-align: center;
    color: white;
    border: none;
    outline: none;

    &.update {
      background-color: #ffa700;
    }
    &.remove {
      background-color: #d62b2b;
    }
    &.save {
      background-color:#67c23a;
    }
    &.cancel {
      background-color: #d62b2b;
    }
  }
  .noUpdate {
    background-color: transparent;
    border: none;
  }
</style>