<template>
  <div class="cof-field" :class="{ padingless: noStyle || noMargin, error: hasError }">
    <label v-if="!noStyle && label">{{ label }}: <span v-if="isRequired" class="required">*</span></label>
    <slot />
    <span v-if="!noStyle && validator" class="errors">
      <span v-for="error in errors" :key="error" class="help-block text-danger">{{ error }}</span>
    </span>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: null
    },

    validator: {
      type: Object,
      default: null
    },

    noMargin: {
      type: Boolean,
      default: false
    },

    noStyle: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      messages: {
        required: 'This field is required.',
        email: 'This should be a valid email.',
        sameAs: 'Passwords must match.',
        integer: 'The value must be integer.',
        numeric: 'This field must be letters only.',
        between: 'The value is out of range.',
        maxLength: 'Maximum character limit exceeded.',
        alpha: 'This field must be letters only.',
        alphaNum: 'This field does not allow special characters.',
        ipAddress: 'The value must be a valid IPv4 address.',
        url: 'The value must be a valid URL.',
        minLength: 'More characters required.',
        minValue: 'The value can not be less than zero.',
        swift: 'Enter a valid SWIFT code.'
      }
    }
  },

  computed: {
    hasError () {
      return this.errors.length > 0
    },

    isRequired () {
      return this.validator && this.validator.hasOwnProperty('required')
    },

    errors () {
      if (this.validator && this.validator.$dirty && !this.validator.$pending) {
        return Object.keys(this.validator).filter((prop) => !prop.startsWith('$') && !this.validator[prop]).map((prop) => {
          if (this.validator.$params[prop].message) {
            return this.validator.$params[prop].message
          }
          return this.messages[prop]
        })
      }

      return []
    }
  }
}
</script>

<style lang="scss" scoped>
.form-group {
  display: inline-block;
  vertical-align: middle;
  width: 155px;
  margin: 0 20px;
  &.padingless {
    padding: 0;
    margin: 0;
  }
  label {
    display: block;
    font-size: 14px;
    font-weight: 300;
    color: #000;
    margin-bottom: 0;
    font-family: 'Roboto', sans-serif;
  }
  .errors {
    display: block;
    min-height: 15px;
    font-size: 11px;
    margin-top: 5px;
    span.text-danger {
      color: #d23131;
    }
  }
  .required {
    color: #d23131;
  }
  .error {
    border: 1px solid #d23131;
    border-radius: 5px;
  }
}
</style>