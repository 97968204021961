<template>
  <div>
    <section>
      <em class="pop-show add-emp" @click="add = true">+ New Provider</em>
    </section>
    <section class="content-filters">
      <div class="cof-box">
        <a href="" class="tsm-handle">Filters</a>
        <!--   <h4>Transaction Search</h4> -->
        <div class="cof-form">
          <a class="cof-close"></a>
          <!--    <h6>Transaction Search</h6> -->
            <div class="cof-row">
              <div class="cof-field">
                <label>Integration Type</label>
                <select v-model="form.type">
                  <option>Payments</option>
                  <option>Manage</option>
                  <option>Reports</option>
                  <option>Compliance</option>
                  <option>FlyBoard</option>
                </select>
              </div>
              <div class="cof-field">
                <label>Provider</label>
                <select v-model="form.provider">
                  <option value="">-</option>
                  <option v-bind:value="provider.shortname" v-bind:data-index="index"
                          v-for="(provider, index) in providers">
                    {{ provider.shortname }}
                  </option>
                </select>
              </div>
              <div class="cof-field mobinl">
                <label>&nbsp;</label>
                <button class="main" @click="search()">Filter</button>
              </div>
              <div class="cof-field mobinl">
                <label>&nbsp;</label>
                <button class="second" @click="resetItem()">Reset Filters</button>
              </div>
            </div>
        </div>
      </div>
    </section>
    <section class="content-tlist">
      <table class="content-table">
        <tr>
          <th>Type</th>
          <th>Provider</th>
          <th>Version</th>
<!--           <th>Module</th> -->
          <th>Certified Date</th>
          <th>Connections</th>
          <th>Status</th>
          <th>Notes</th>
          <th>Options</th>
        </tr>
        <tr v-for="(item, index) in data">
          <td><span>Type</span>
            <template v-if="!isEdit(index)">{{item.type}}</template>
            <select v-else v-model="item.type">
              <option>Payments</option>
              <option>Manage</option>
              <option>Reports</option>
              <option>Compliance</option>
              <option>FlyBoard</option>
            </select>
          </td>
          <td><span>Provider</span>
            <template v-if="!isEdit(index)"><a v-on:click="popupProvider(item)">{{item.shortname}}</a></template>
            <input type="text" v-else v-model="item.shortname" />
          </td>
          <td><span>Version</span>
            <template v-if="!isEdit(index)">{{item.version}}</template>
            <input type="text" v-else v-model="item.version" />
          </td>
<!--           <td><span>Module</span>{{providerModules[item.module] || item.module}}</td> -->
          <td><span>Certified Date</span>
            <template v-if="!isEdit(index)">{{item.certDate}}</template>
            <vue-englishdatepicker v-else v-model="item.certDate"/>
          </td>
          <td><span>Connections</span>
            <template v-if="!isEdit(index)">{{item.connections}}</template>
            <input type="text" v-else v-model="item.connections" />
          </td>
          <td v-on:click="openUpdateStatus(item.id, item.status)"><span>Status</span><em v-if="item.status === 'Active'">Active</em><em v-if="item.status !== 'Active'" class="c2">{{item.status}}</em></td>
          <td><span>Notes</span>
            <template v-if="!isEdit(index)"><div>{{item.notes}}</div></template>
            <input type="text" v-else v-model="item.notes" />
          </td>
          <td><span>Options</span>
            <template v-if="isEdit(index)">
              <button @click="saveUpdate(index)" class="button-handle save">Save</button>
              <button @click="cancelEdit(index)" class="button-handle cancel">Cancel</button>
            </template>
            <template v-else>
              <button v-if="item.status == 'Disabled'" class="second" v-on:click="deleteItem(item.id)">Remove</button>
              <button @click="edit(index)" class="button-handle update">Update</button>
            </template>
          </td>
        </tr>
      </table>
    </section>
    <section class="popup" id="providerPopup">
      <div class="pop-inner">
        <div class="pop-box">
          <a class="close"></a>
          <h2>{{popupItem.provider}}</h2>
          <div class="pf-row split">
            <div class="pf-field">
              <label>{{popupItem.system}}</label>
            </div>
          </div>
          <div class="pf-row split">
            <div class="pf-field">
              <label>{{popupItem.connections}}</label>
            </div>
          </div>
          <div class="pf-row buttons">
            <a href="" class="pop-btn close-popup">Cancel</a>
          </div>
        </div>
      </div>
    </section>
    <section class="popup" id="statusPopup">
      <div class="pop-inner">
        <div class="pop-box">
          <a class="close"></a>
          <h2>Update Status</h2>
          <div class="pf-row split">
            <div class="pf-field">
              <select v-model="status">
                <option value="Review">Review</option>
                <option value="Testing">Testing</option>
                <option value="Active">Active</option>
                <option value="Disabled">Disabled</option>
              </select>
            </div>
          </div>
          <div class="pf-row buttons">
            <span class="pop-btn" v-on:click="updateStatus()">Confirm</span>
            <a href="" class="pop-btn close-popup">Cancel</a>
          </div>
        </div>
      </div>
    </section>
    <popup :open.sync="add">
      <h2>Add New Provider</h2>
      <form class="pop-form" v-on:submit.prevent="create()">
        <h3>PROVIDER INFORMATION</h3>
        <div class="pf-row split">
          <div class="pf-field">
            <label>Provider Integration Type</label>
            <select v-model="newItem.type" required :disabled="editStatus">
              <option>Payments</option>
              <option>Manage</option>
              <option>Reports</option>
              <option>Compliance</option>
              <option>FlyBoard</option>
            </select>
          </div>
          <div class="pf-field">
            <label>Provider Name</label>
            <input type="text" v-model="newItem.provider" required :disabled="editStatus"/>
          </div>
        </div>
        <div class="pf-row split">
          <div class="pf-field">
            <label>Provider Short Name</label>
            <input type="text" v-model="newItem.shortname" required :disabled="editStatus"/>
          </div>
          <div class="pf-field">
            <label>Version</label>
            <input type="text" v-model="newItem.version" required :disabled="editStatus"/>
          </div>

        </div>
        <div class="pf-row split">
          <div class="pf-field">
            <label>Module</label>
            <select v-model="newItem.module" required :disabled="editStatus">
              <option v-for="(optLabel, optVal) in providerModules" :value="optVal">{{ optLabel }}</option>
            </select>
          </div>
          <div class="pf-field">
            <label>Certification Date</label>
            {{newItem.certDate | date('MMMM D YYYY')}}
          </div>
        </div>
        <div class="pf-row">
          <div class="pf-field">
            <label>Connections</label>
            <input type="text"  v-model="newItem.connections" required :disabled="editStatus"/>
          </div>
        </div>
        <div class="pf-row">
          <div class="pf-field">
            <label>Notes</label>
            <input type="text"  v-model="newItem.notes" :disabled="editStatus"/>
          </div>
        </div>
        <div class="pf-row">
          <div class="pf-field">
            <label>Document Upload</label>
            <a v-if="editStatus" :href="newItem.document">{{newItem.filename}}</a>
            <input v-else type="file" ref="file" v-on:change="handleFileUpload()"/>
          </div>
        </div>
        <h3>SYSTEM INTEGRATIONS</h3>
        <div class="pf-row" v-for="system in systems">
          <div class="pf-field" >
            <label>{{system.system}} Connections: {{system.connections}}</label>
          </div>
        </div>
        <h3>PROVIDER SETTINGS</h3>
        <div class="pf-row buttons">
          <a class="pop-btn close-popup" @click="add = false">Cancel</a>
          <a v-if="editStatus" class="edit pop-btn" @click="editStatus = false">Edit</a>
          <button v-else class="pop-btn">Save</button>
        </div>
      </form>
    </popup>
  </div>
</template>

<script>
  import VueEnglishdatepicker from 'vue-englishdatepicker';

  export default {
    components: {
      VueEnglishdatepicker,
    },

    data: () => ({
      editing: [],
      status: '',
      statusId: '',
      items: [],
      newItem: {
        type: "",
        provider: "",
        version: "",
        connections: "",
        notes: "",
        document: "",
        filename: ""
      },
      popupItem: [],
      providerModules: window.providerModules,
      add: false,
      editStatus: false,

      appliedFilters: null,

      form: {
        type: null,
        provider: null
      },

      systems: null,
      providers: [],
    }),

    computed: {
      data () {
        let data = this.items || []

        if (!this.appliedFilters) return data

        if (this.appliedFilters.type) {
          data = data.filter(item => item.type == this.appliedFilters.type)
        }

        if (this.appliedFilters.provider) {
          data = data.filter(item => item.shortname == this.appliedFilters.provider)
        }

        return data
      },
    },

    methods: {
      search () {
        this.appliedFilters = { ...this.form }
      },

      resetItem() {
        this.appliedFilters = null
        Object.keys(this.form).forEach(key => this.form[key] = null)
      },

      async reload() {
        this.items = (await api.list("Provider", {}, ["provider:ASC"])).items;
        this.providers = (await api.list("Provider", {}, ["id:ASC"])).items;
      },
      async createItem() {
        await api.create("Provider", this.newItem, true);
        this.reload();
        this.add = false
        this.newItem = {
          type: "",
          provider: "",
          version: "",
          connections: "",
          notes: "",
          document: "",
        }
      },

      async create() {
        if(this.newItem.id){
          await api.update("Provider", this.newItem.id, this.newItem, true);
        }else{
          this.newItem.status = 'Review'
          await api.create("Provider", this.newItem, true);
        }
        this.reload();
        this.add = false
        this.newItem = {
          type: "",
          provider: "",
          version: "",
          connections: "",
          notes: "",
          document: "",
          module: "",
          shortname: ""
        }
      },

      async deleteItem(id) {
        var r = confirm("Are you sure?");
        if (r == true) {
          await api.delete("Provider", id);
          this.reload();
        } else {
          return;
        }

      },

      handleFileUpload(){
        this.newItem.document = this.$refs.file.files[0];
      },

      async popupProvider(item) {
        this.newItem = item
        this.systems = (await api.list("SystemIntegrations", {"provider": item.provider}, ["provider:ASC"])).items;
        this.add = true
        this.editStatus = true
      },

      async openUpdateStatus(id, status) {
        this.statusId = id;
        this.status = status;
        $("#statusPopup").toggleClass("open");
      },

      async updateStatus() {
        await api.update("Provider", this.statusId, {"status":this.status}, true);
        $("#statusPopup").toggleClass("open");
        this.reload();
      },

      async saveUpdate (i) {
        try {
          const payload = {
            type: this.items[i].type,
            provider: this.items[i].provider,
            version: this.items[i].version,
            connections: this.items[i].connections,
            notes: this.items[i].notes,
            certDate: this.items[i].certDate
          }
          await api.update("Provider", this.items[i].id, payload, true);
          this.cancelEdit(i)
        } catch (e) {
        }
      },

      cancelEdit (i) {
        const index = this.editing.indexOf(i)
        this.editing.splice(index, 1)
      },

      isEdit (index) {
        return this.editing.includes(index)
      },

      edit(index) {
        this.editing.push(index)
      },
    },
    mounted() {
      this.reload();
    },
  }
</script>
<style lang="scss" scoped>
  h3 {
    color: #0275be;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;
    border-bottom: 1px solid #99aabb;
  }

  .pop-form button.main {
    background: #0480c6;
    color: white;
    min-width: 120px;
    border: 0;
    box-sizing: border-box;
    height: 29px;
    border-radius: 15px;
    font-size: 12px;
    font-weight: bold;
  }
  .edit.pop-btn {
    margin-left: 16px;
    background: #0480c6 !important;
    border-color: transparent;
    color: white !important;
  }
</style>