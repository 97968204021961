<template>
  <div v-if="message" class="sysmsg">
    <div class="msginfo">
      <h2>System Messages</h2>
      <time>{{ message.createdAt | date }}</time>
      <div class="smsgctl">
        <span class="pos">{{ index + 1}} / {{ messages.length }}</span>
        <a class="prev" @click="prevMessage()"></a>
        <a class="next" @click="nextMessage()"></a>
      </div>
    </div>
    <ul class="sysmsgc">
      <li class="active">
        <div ref="overflow" :class="{ 'none-overflow': readMore, 'sysmsgb': true }">
         {{ message.message }}
        </div>
      </li>
    </ul>
    <div class="read-more-container" v-if="isEllypsisActive">
      <button class="read-more" @click="readMore = !readMore">{{ readMore ? 'Read less' : 'Read more' }}</button>
    </div>
  </div>
</template>

<script>
import { EventBus } from './app';

export default {
  name: 'header-system-messages',

  data() {
    return {
      index: 0,
      maxChar: 100,
      readMore: false,
      isEllypsisActive: false,
      messages: []
    }
  },

  computed: {
    showReadMore () {
      return this.overflow
    },

    message () {
      return this.messages && this.messages.length ? this.messages[this.index] : null
    },
  },

  watch: {
    message: {
      handler () {
        this.setEllypsis()
      },
      immediate: true
    }
  },

  async created () {
    this.load()
    await api.list("SystemMessage", {}, ["id:ASC"])
    await EventBus.$on('message-updated', () => this.load())
    this.setEllypsis()
    window.addEventListener("resize", this.setEllypsis)
  },

  destroyed() {
    window.removeEventListener("resize", this.setEllypsis);
  },

  methods: {
    async load() {
      this.messages = (await api.list("SystemMessage", {}, ["id:ASC"])).items;
    },

    nextMessage () {
      if (this.index + 1 > this.messages.length - 1) this.index = 0
      else this.index++
    },

    prevMessage () {
      if (this.index - 1 < 0) this.index = this.messages.length - 1
      else this.index--
    },

    setEllypsis () {
      if(this.$refs.overflow) {
        this.$nextTick(() => {
          this.isEllypsisActive = this.$refs.overflow.scrollWidth > this.$refs.overflow.clientWidth
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.sysmsg {
  max-width: 700px;
  padding-right: 10px;
}
.sysmsgb {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &.none-overflow {
    text-overflow: unset;
    white-space: unset;
  }
}
.sysmsgc {
  width: calc(100% - 244px);
}
.read-more-container {
  width: 60px;
  padding-left: 10px; 
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  .read-more {
    width: 60px; 
    color: #fff;
    background: #0480c6;
    border: 0;
    height: 29px; 
    box-sizing: border-box;
    border-radius: 15px;
    font-size: 10px;
    font-weight: bold;

    &:focus { outline: none; }
  }
}
</style>