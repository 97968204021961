var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    { staticClass: "notifications", class: { visible: _vm.messages.length } },
    _vm._l(_vm.messages, function(message) {
      return _c(
        "li",
        {
          key: message.id,
          class: message.cls,
          on: {
            click: function($event) {
              return _vm.dismissMessage(message)
            }
          }
        },
        [_vm._v("\n    " + _vm._s(message.message) + "\n  ")]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }