var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.message
    ? _c("div", { staticClass: "sysmsg" }, [
        _c("div", { staticClass: "msginfo" }, [
          _c("h2", [_vm._v("System Messages")]),
          _vm._v(" "),
          _c("time", [_vm._v(_vm._s(_vm._f("date")(_vm.message.createdAt)))]),
          _vm._v(" "),
          _c("div", { staticClass: "smsgctl" }, [
            _c("span", { staticClass: "pos" }, [
              _vm._v(
                _vm._s(_vm.index + 1) + " / " + _vm._s(_vm.messages.length)
              )
            ]),
            _vm._v(" "),
            _c("a", {
              staticClass: "prev",
              on: {
                click: function($event) {
                  return _vm.prevMessage()
                }
              }
            }),
            _vm._v(" "),
            _c("a", {
              staticClass: "next",
              on: {
                click: function($event) {
                  return _vm.nextMessage()
                }
              }
            })
          ])
        ]),
        _vm._v(" "),
        _c("ul", { staticClass: "sysmsgc" }, [
          _c("li", { staticClass: "active" }, [
            _c(
              "div",
              {
                ref: "overflow",
                class: { "none-overflow": _vm.readMore, sysmsgb: true }
              },
              [_vm._v("\n       " + _vm._s(_vm.message.message) + "\n      ")]
            )
          ])
        ]),
        _vm._v(" "),
        _vm.isEllypsisActive
          ? _c("div", { staticClass: "read-more-container" }, [
              _c(
                "button",
                {
                  staticClass: "read-more",
                  on: {
                    click: function($event) {
                      _vm.readMore = !_vm.readMore
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.readMore ? "Read less" : "Read more"))]
              )
            ])
          : _vm._e()
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }