var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "section",
      { staticClass: "content-filters" },
      [
        _c("basic-form", [
          _c("div", { staticClass: "cof-row" }, [
            _c(
              "div",
              { staticClass: "cof-field" },
              [
                _c("mnb-input", {
                  attrs: { label: "Version" },
                  model: {
                    value: _vm.form.version,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "version", $$v)
                    },
                    expression: "form.version"
                  }
                }),
                _vm._v(" "),
                _vm.$v.form.version.$dirty && _vm.$v.form.version.$invalid
                  ? _c("span", { staticClass: "error" }, [
                      _vm._v("This field is required !")
                    ])
                  : _vm._e()
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "full-w" },
              [
                _c("mnb-textarea", {
                  attrs: {
                    label: "Description",
                    classes: { full: true, noResize: true },
                    full: ""
                  },
                  on: { input: _vm.mixin_autoResize_resize },
                  model: {
                    value: _vm.form.description,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "description", $$v)
                    },
                    expression: "form.description"
                  }
                }),
                _vm._v(" "),
                _vm.$v.form.description.$dirty &&
                _vm.$v.form.description.$invalid
                  ? _c("span", { staticClass: "error" }, [
                      _vm._v("This field is required !")
                    ])
                  : _vm._e()
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "cof-row" }, [
            _c("div", { staticClass: "cof-field mobinl" }, [
              _c("label", [_vm._v(" ")]),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "main",
                  on: {
                    click: function($event) {
                      return _vm.save()
                    }
                  }
                },
                [_vm._v("Add Change Log")]
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "cof-field mobinl" }, [
              _c("label", [_vm._v(" ")]),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "second",
                  on: {
                    click: function($event) {
                      return _vm.resetField()
                    }
                  }
                },
                [_vm._v("Reset Fields")]
              )
            ])
          ])
        ])
      ],
      1
    ),
    _vm._v(" "),
    _c("section", { staticClass: "content-tlist" }, [
      _c(
        "table",
        { staticClass: "content-table" },
        [
          _vm._m(0),
          _vm._v(" "),
          _vm._l(_vm.logsSorted, function(item, i) {
            return _c("tr", { key: i }, [
              _c("td", [
                _c("span", [_vm._v("Date")]),
                _vm._v(
                  _vm._s(_vm._f("date")(item.createdAt, "dddd, MMMM D YYYY"))
                )
              ]),
              _vm._v(" "),
              _c(
                "td",
                [
                  _c("span", [_vm._v("Version")]),
                  _vm._v(" "),
                  !_vm.isEdit(i)
                    ? [_vm._v(_vm._s(item.version))]
                    : _c("mnb-input", {
                        ref: "input_" + i,
                        refInFor: true,
                        model: {
                          value: _vm.editingVersion[i],
                          callback: function($$v) {
                            _vm.$set(_vm.editingVersion, i, $$v)
                          },
                          expression: "editingVersion[i]"
                        }
                      })
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "td",
                { staticStyle: { width: "500px", "padding-right": "10px" } },
                [
                  _c("span", [_vm._v("Description")]),
                  _vm._v(" "),
                  !_vm.isEdit(i)
                    ? [_vm._v(" " + _vm._s(item.description))]
                    : _c("mnb-textarea", {
                        attrs: { classes: { noResize: true }, full: "" },
                        on: { input: _vm.mixin_autoResize_resize },
                        model: {
                          value: _vm.editingText[i],
                          callback: function($$v) {
                            _vm.$set(_vm.editingText, i, $$v)
                          },
                          expression: "editingText[i]"
                        }
                      })
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "td",
                [
                  _c("span", [_vm._v("Options")]),
                  _vm._v(" "),
                  _vm.isEdit(i)
                    ? [
                        _c(
                          "button",
                          {
                            staticClass: "message-handle save",
                            on: {
                              click: function($event) {
                                return _vm.saveUpdate(i)
                              }
                            }
                          },
                          [_vm._v("Save")]
                        ),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "message-handle cancel",
                            on: {
                              click: function($event) {
                                return _vm.cancelEdit(i)
                              }
                            }
                          },
                          [_vm._v("Cancel")]
                        )
                      ]
                    : [
                        _c(
                          "button",
                          {
                            staticClass: "message-handle update",
                            on: {
                              click: function($event) {
                                return _vm.editLog(i)
                              }
                            }
                          },
                          [_vm._v("Update")]
                        ),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "message-handle remove",
                            on: {
                              click: function($event) {
                                return _vm.deleteLog(item.id)
                              }
                            }
                          },
                          [_vm._v("Remove")]
                        )
                      ]
                ],
                2
              )
            ])
          })
        ],
        2
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("th", { staticStyle: { width: "20%" } }, [_vm._v("Date")]),
      _vm._v(" "),
      _c("th", { staticStyle: { width: "20%" } }, [_vm._v("Version")]),
      _vm._v(" "),
      _c("th", { staticStyle: { width: "40%" } }, [_vm._v("Description")]),
      _vm._v(" "),
      _c("th", { staticStyle: { width: "20%" } }, [_vm._v("Options")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }